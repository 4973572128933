define("momentumhub/controllers/study-part-builder", ["exports", "prettier"], function (_exports, _prettier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    moduleArray: [{
      "id": 1,
      "previousTriggerPoint": "",
      "triggerpoints": [{
        "id": "1",
        "rule": 0,
        "moduleId": null,
        "previousTriggerPoint": ""
      }]
    }],
    moduleIds: [],
    zoom: 100,
    editModule: true,
    testRules: [{
      "id": 1,
      "rule": " score > 5"
    }, {
      "id": 2,
      "rule": " score > 8"
    }, {
      "id": 3,
      "rule": " score = 10"
    }],
    actions: {
      // Open & close mobile menu
      open_close_menu: function open_close_menu() {
        $('.assets-component').css({
          padding: '40px'
        }, 100);
        $('.assets-component').animate({
          width: '300px'
        }, 500);
        $('.close').show(100);
        $('.inner-component').show(100);
      },
      open_close_rules_Menu: function open_close_rules_Menu() {
        $('.rule-assets').css({
          padding: '40px'
        }, 100);
        $('.rule-assets').animate({
          width: '800px'
        }, 500);
        $('.close').show(100);
        $('.inner-component1').show(500);
      },
      close: function close() {
        $('.inner-component').hide();
        $('.close').hide();
        $('.assets-component').css({
          padding: '0px'
        }, 400);
        $('.assets-component').animate({
          width: '0px'
        }, 400);
      },
      //Triger Points
      openTriggerPointComponent: function openTriggerPointComponent(id) {
        $('#trigerpoint' + id).slideDown(1000);
      },
      addTrigerPoint: function addTrigerPoint(tiggerpointId, moduleId, triggerpointindex, listindex) {
        var checkModuleSurvay = this.checkChildDiv(tiggerpointId);
        if (checkModuleSurvay) {
          var that = this;
          var newModuleId = moduleId + 1;
          var numberOfTriggerPoints = document.getElementById("trigger-point-input" + tiggerpointId).value;
          var triggerpointsArray = [];
          var temp = this.moduleArray;
          var i;
          for (i = 1; i <= numberOfTriggerPoints; i++) {
            var trigger_id = tiggerpointId + "" + i;
            var triggerPointObj = {
              "id": trigger_id,
              "rule": 0,
              "moduleId": "",
              "previousTriggerPoint": tiggerpointId
            };
            triggerpointsArray.pushObject(triggerPointObj);
          }
          var obj = {
            "id": newModuleId,
            "previousTriggerPoint": tiggerpointId,
            "triggerpoints": triggerpointsArray
          };
          temp.pushObject(obj);
          this.set('moduleArray', temp);

          //Disable input
          $('#trigger-point-input' + tiggerpointId).prop("disabled", true);
          var triggerPointArrayLength = triggerpointsArray.length;
          var randomColor = that.generateRandomColorRgb();
          triggerpointsArray.forEach(function (ids, index) {
            setTimeout(function () {
              setTimeout(function () {
                $('#trigerpointrule' + ids.id).slideDown(1000);
              }, 300);
              var newId = ids.id.slice(0, -1);
              var idSum = that.numberAdd(newId);
              if (that.moduleIds.includes(moduleId)) {
                var newTriggerPoint = document.getElementById('each-trigger-point' + ids.id);
                $("#each-part-component" + newModuleId).append(newTriggerPoint);
              }
              if (triggerPointArrayLength === index + 1) {
                that.divPositioning(moduleId);
              }
              //Assigning Colors
              $("#bottom" + ids.previousTriggerPoint).css({
                "background-color": randomColor
              });
              $("#top" + ids.id).css({
                "background-color": randomColor
              });
              $("#bottom-arrow" + ids.previousTriggerPoint).css({
                "color": randomColor
              });
              $("#bottom-arrow" + ids.previousTriggerPoint).show();
            }, 0);
          });
          console.log(that.moduleArray);
        } else {
          alert("Please assign Module / Survey first.");
        }
      },
      // Zoom in/out of the canvas
      zoomInOut: function zoomInOut(input) {
        var zoom = this.zoom;
        if (input === "zoomout") {
          zoom = zoom + 5;
          this.set('zoom', zoom);
          var text = zoom + "%";
          $('.each-part-component').css({
            zoom: text
          });
          setTimeout(function () {
            var overflow = $(".scrollable-component").get(0);
            $('.each-part-component').animate({
              width: overflow.scrollWidth
            }, 500);
          }, 10);
        } else {
          zoom = zoom - 5;
          this.set('zoom', zoom);
          var text = zoom + "%";
          $('.each-part-component').css({
            zoom: text
          });
          setTimeout(function () {
            var overflow = $(".scrollable-component").get(0);
            $('.each-part-component').animate({
              width: overflow.scrollWidth
            }, 500);
          }, 10);
        }
      },
      //Assign rules 
      assignRules: function assignRules(triggerPointId, triggerPointIndex, listIndex) {
        var e = document.getElementById("selectRules" + triggerPointId);
        var ruleId = e.options[e.selectedIndex].value;
        if (ruleId != "selin") {
          var temp = [];
          temp = this.moduleArray;
          temp[listIndex].triggerpoints[triggerPointIndex].rule = ruleId;
          this.set('moduleArray', temp);
        }
      },
      //Show edit buttons
      editModuleComponent: function editModuleComponent() {
        var editModule = this.get('editModule');
        if (editModule) {
          $('.inner-module-container').hide();
          $('.change-text-module').show();
          this.set('editModule', false);
        } else {
          $('.change-text-module').hide();
          $('.inner-module-container').show();
          this.set('editModule', true);
        }
      },
      assignBackModule: function assignBackModule(tiggerpointId) {
        var checkModuleSurvay = this.checkChildDiv(tiggerpointId);
        if (checkModuleSurvay) {
          var r = confirm("Are you sure you want to remove current Module / Survey ?");
          if (r) {
            var moduleSurveyId = $('#' + tiggerpointId + '> .inner-module-container > .each-module-asset');
            // var t = document.getElementById(moduleSurveyId);
            $(".each-module-assets").append(moduleSurveyId);
            $('#change-text-module' + tiggerpointId).hide();
            $('#inner-module-container' + tiggerpointId).show();
            $('#textinner-module-container' + tiggerpointId).show();
            $('#container-buttonsinner-module-container' + tiggerpointId).hide();
            $('#alreadyupdated-buttoninner-module-container' + tiggerpointId).show();
          }
        } else {
          alert("There is no module / survey currently assigned to this div.");
        }
      },
      updateModule: function updateModule(triggerpointId, moduleIndex, triggerpointIndex) {
        var checkModuleSurvay = this.checkChildDiv(triggerpointId);
        if (checkModuleSurvay) {
          var newModuleSurveyId = $('#' + triggerpointId + '> .inner-module-container > .each-module-asset').attr('id');
          var tempArray = this.moduleArray;
          this.set('moduleArray', []);
          tempArray[moduleIndex].triggerpoints[triggerpointIndex].moduleId = newModuleSurveyId;
          this.set('moduleArray', tempArray);
          var t = this.moduleArray;
          console.log(t);
          $('#alreadyupdated-buttoninner-module-container' + triggerpointId).hide();
          $('#container-buttonsinner-module-container' + triggerpointId).show();
        } else {
          alert("There is no module / survey currently assigned to this div.");
        }
      },
      addNewModule: function addNewModule() {},
      deleteNodeModule: function deleteNodeModule(modeuleIndex, triggerpointId, triggerpointIndex) {
        var r = confirm("Are you sure you want to remove this node and its child nodes ?");
        if (r) {
          var tempModuleArray = [];
          tempModuleArray = this.moduleArray;
          this.set('moduleArray', []);
          var previousTriggerPoint = tempModuleArray[modeuleIndex].previousTriggerPoint;
          tempModuleArray.forEach(function (items, index) {
            items.triggerpoints.forEach(function (subitems, subindex) {
              if (subitems.id.startsWith(triggerpointId)) {
                delete tempModuleArray[index].triggerpoints[subindex];
                $('#each-trigger-point' + subitems.id).hide();
              }
            });
          });
          this.set('moduleArray', tempModuleArray);
          console.log(this.moduleArray);

          //Updating Input Value
          document.getElementById('trigger-point-input' + previousTriggerPoint).value = tempModuleArray[modeuleIndex].triggerpoints.length;
        }
      }
    },
    divPositioning: function divPositioning(moduleId) {
      // Taking care of positioning of Divs
      var that = this;
      var overflow = $(".scrollable-component").get(0);
      $('.each-part-component').animate({
        width: overflow.scrollWidth
      }, 500);
      that.moduleIds.pushObject(moduleId);

      //Zoom divs as per set value
      var text = that.zoom + "%";
      $('.each-part-component').css({
        zoom: text
      }, 500);

      // Horizontally center the scrollable div

      var myDiv = $(".scrollable-component");
      var scrollto = myDiv.offset().left + myDiv.width() / 2;
      myDiv.animate({
        scrollLeft: scrollto
      });
    },
    // Tets if module / survey is assigned to div or not
    checkChildDiv: function checkChildDiv(parentId) {
      var condition = false;
      var childDivLength = $('#' + parentId).has('.each-module-asset').length;
      if (childDivLength) {
        condition = true;
      }
      return condition;
    },
    generateRandomColorRgb: function generateRandomColorRgb() {
      var red = Math.floor(Math.random() * 256);
      var green = Math.floor(Math.random() * 256);
      var blue = Math.floor(Math.random() * 256);
      return "rgb(" + red + ", " + green + ", " + blue + ")";
    },
    numberAdd: function numberAdd(str) {
      var strArr = str.split(""),
        strTemp = 0;
      var i;
      for (i = 0; i < strArr.length; i++) {
        if (!isNaN(strArr[i])) {
          strTemp += parseInt(strArr[i]);
        }
      }
      return strTemp;
    }
  });
  _exports.default = _default;
});
define("momentumhub/routes/participant-details", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model(params) {
      var url = _environment.default.APP.API_HOST + '/users/' + params.userId + '/participant-details';
      this.set('userId', params.userId);
      var accessToken = this.get('session.data.authenticated.access_token');
      return $.ajax({
        url: url,
        type: 'GET',
        headers: {
          'Authorization': 'Bearer ' + accessToken
        },
        success: function success(res) {
          return res;
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      console.log(model);
      controller.set('userId', this.get('userId'));
      controller.getReports();
      controller.getMessages();
    }
  });
  _exports.default = _default;
});
define("momentumhub/controllers/create-module", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    hedgeDocUrl: "",
    hedgePreviewDocUrl: "",
    isEditable: false,
    module: [],
    session: Ember.inject.service(),
    module_id: "",
    showPreview: false,
    previewpages: [],
    pageSelected: 0,
    actions: {
      // Open & close Assets
      open_close_menu: function open_close_menu() {
        $('#open-assets-button').hide();
        $('.inner-component').show();
        $('.assets-component').css({
          padding: '20px 20px'
        }, 100);
        $('.assets-component').animate({
          width: '230px'
        }, 500);
        $('.close').show(100);
        $('.create-module-center').animate({
          width: 'calc(100% - 315px)'
        }, 300);
        $('.create-module-right').animate({
          width: '315px'
        }, 300);
      },
      totalSlides: function totalSlides() {
        var totalSlides = localStorage.getItem("totalSlides");
        var totalSlidesArray = [];
        if (totalSlides != null) {
          var pageSelected = this.pageSelected;
          for (var i = 1; i <= totalSlides; i++) {
            totalSlidesArray.push(i);
          }
          this.set('previewpages', totalSlidesArray);
          setTimeout(function () {
            $("#delivery_type").prop('selectedIndex', pageSelected);
          }, 10);
        }
      },
      pageSelected: function pageSelected() {
        var l = document.getElementById("delivery_type");
        var pageSelected = l.options[l.selectedIndex].value;
        var pageSelected_1 = parseInt(pageSelected) - 1;
        this.set('pageSelected', pageSelected_1);
        var hedgeDocUrl = _environment.default.APP.HEDGE_DOC + "/p/" + this.module_id + "#/" + pageSelected_1;
        this.set('hedgePreviewDocUrl', hedgeDocUrl);
      },
      showEditTitle: function showEditTitle() {
        var isEditable = this.isEditable;
        var module = this.module;
        var accessToken = this.get('session.data.authenticated.access_token');
        var that = this;
        var moduleId = module.id;
        if (isEditable) {
          var module_name = this.get('module_name');
          var mod_description = this.get('mod_description');
          if (module_name) {
            var dateUpdated = Date();
            var apidata = {
              "name": module_name,
              "date_updated": dateUpdated,
              "mod_description": mod_description
            };
            $.ajax({
              url: _environment.default.APP.API_HOST + '/modules/' + moduleId,
              type: 'PATCH',
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              data: apidata,
              success: function success(res) {
                console.log(res);
                that.send('refreshCurrentRoute');
                that.set('isEditable', false);
              },
              error: function error(err, xH) {
                var errorMessage = err.responseText;
                alert(errorMessage);
              }
            });
          } else {}
        } else {
          this.set('module_name', module.name);
          this.set('mod_description', module.mod_description);
          this.set('isEditable', true);
        }
      },
      closeEditTitle: function closeEditTitle() {
        this.set('isEditable', false);
      },
      toggleButton: function toggleButton(type, is_page) {
        var that = this;
        if (type === "editor") {
          this.set('showPreview', false);
          $('.center').css({
            "width": 'calc(100% - 270px);'
          });
          $('.media-choices2').css({
            "color": 'black'
          });
          $('.shadow1').animate({
            left: '0px'
          }, 300);
          $('.edititor1').css({
            "color": 'white'
          });
          $('.editcontainer').show();
          $('.previewcontainer').hide();
          // var hedgeDocUrl = ENV.APP.HEDGE_DOC + "/" + this.module_id + '?both';
          // this.set('hedgeDocUrl', hedgeDocUrl)
        } else {
          this.set('showPreview', true);
          var pageSelected = this.pageSelected;
          setTimeout(function () {
            $('.center').css({
              "width": '100%'
            });
            $('.editcontainer').hide();
            $('.previewcontainer').show();
            $('.media-choices2').css({
              "color": 'black'
            });
            $('.shadow1').animate({
              left: '50%'
            }, 300);
            $('.slidemode').css({
              "color": 'white'
            });
            if (is_page) {
              var hedgeDocUrl = "/hedgedoc/s/" + that.module_id;
            } else {
              var hedgeDocUrl = "/hedgedoc/p/" + that.module_id + "#/" + pageSelected;
            }
            that.set('hedgePreviewDocUrl', hedgeDocUrl);
            document.getElementById('previewcontainer').contentWindow.location.href = hedgeDocUrl;
            document.getElementById('previewcontainer').contentWindow.location.reload();
          }, 10);
        }
      }
    }
  });
  _exports.default = _default;
});
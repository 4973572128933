define("momentumhub/routes/usermanagement/links", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model() {
      var user_type = this.controllerFor("usermanagement.sites").get('user_type');
      if (user_type == "SA") {
        return Ember.RSVP.hash({
          studies: this.store.findAll('study'),
          sites: this.store.findAll('mommentumhubsite'),
          participantGroups: this.store.findAll('participantgroup')
        });
      } else {
        return Ember.RSVP.hash({
          studies: this.store.findAll('study'),
          sites: this.store.findAll('mommentumhubsite'),
          participantGroups: this.store.findAll('participantgroup')
        });
      }
    },
    afterModel: function afterModel(model) {
      var user_type = this.controllerFor("usermanagement.sites").get('user_type');
      this.controllerFor("usermanagement.links").set('studies', model.studies);
      this.controllerFor("usermanagement.links").set('selectedStudy', []);
      this.controllerFor("usermanagement.links").set('selectedSite', []);
      this.controllerFor("usermanagement.links").set('selectedGroup', []);
      this.controllerFor("usermanagement.links").set('sites', []);
      this.controllerFor("usermanagement.links").set('participantGroups', []);
      this.controllerFor("usermanagement.links").set('createdLink', "");
      this.controllerFor("usermanagement.links").set('errorMessage', "");
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });
  _exports.default = _default;
});
define("momentumhub/routes/usermanagement", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model() {
      var user_email = this.session.get('data.user_email');
      return Ember.RSVP.hash({
        users: this.store.query('user', {
          email__iexact: user_email
        })
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.manageFunctionality(model);
    },
    manageFunctionality: function manageFunctionality(model) {
      var userModel = model.users;
      if (userModel.get('firstObject')) {
        var user = userModel.get('firstObject');
        var userType = user.get('user_type');

        //inject user types in controllers
        this.controllerFor("usermanagement.users").set('user_type', userType);
        this.controllerFor("usermanagement.groups").set('user_type', userType);
        this.controllerFor("usermanagement.sites").set('user_type', userType);
        this.controllerFor("usermanagement.studies").set('user_type', userType);
        this.controllerFor("usermanagement.studies").set('currentUser', user);
        //Default
        this.controllerFor("usermanagement").set('showUserRoute', false);
        this.controllerFor("usermanagement").set('showGroupRoute', false);
        this.controllerFor("usermanagement").set('showSiteRoute', false);
        this.controllerFor("usermanagement").set('showStudyRoute', false);
        this.controllerFor("usermanagement").set('showLinksRoute', false);

        // Send user to dashboard if they are not researcher, site admin or super admin
        if (userType == "RS" || userType == "PA" || userType == "SA") {} else {
          this.get('router').transitionTo('dashboard');
        }

        // Super Admin
        if (userType == "SA") {
          //1. show users page
          this.controllerFor("usermanagement").set('showUserRoute', true);
          this.controllerFor("usermanagement").set('showGroupRoute', true);
          this.controllerFor("usermanagement").set('showSiteRoute', true);
          this.controllerFor("usermanagement").set('showStudyRoute', true);
          this.controllerFor("usermanagement").set('showLinksRoute', true);
        }

        //Site Admin
        if (userType == "PA") {
          //1. show users page
          this.controllerFor("usermanagement").set('showUserRoute', true);
          this.controllerFor("usermanagement").set('showGroupRoute', true);
          this.controllerFor("usermanagement").set('showSiteRoute', true);
          this.controllerFor("usermanagement").set('showStudyRoute', true);
          this.controllerFor("usermanagement").set('showLinksRoute', true);
        }

        //Researcher
        if (userType == "RS") {
          //1. show users page
          this.controllerFor("usermanagement").set('showUserRoute', true);
          this.controllerFor("usermanagement").set('showGroupRoute', true);
          this.controllerFor("usermanagement").set('showSiteRoute', false);
          this.controllerFor("usermanagement").set('showStudyRoute', true);
        }
      }
    }
  });
  _exports.default = _default;
});
define("momentumhub/adapters/application", ["exports", "momentumhub/config/environment", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _environment, _emberData, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service('session'),
    authorizer: 'authorizer:application',
    host: _environment.default.APP.API_HOST,
    headers: Ember.computed('session.authToken', function () {
      var auth = _environment.default.APP.super_user;
      if (this.get('session.isAuthenticated')) {
        auth = "Bearer " + this.get("session.data.authenticated.access_token");
      }
      return {
        "Authorization": auth,
        "Content-Type": "application/json"
      };
    }),
    pathForType: function pathForType(type) {
      if (type === 'mountain-example') {
        return 'mountain-examples';
      }
      return Ember.Inflector.inflector.pluralize(type);
    }
  });
  _exports.default = _default;
});
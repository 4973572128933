define("momentumhub/helpers/custom-widgets/random-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RandomSelect = {
    name: 'randomselect',
    title: 'Random Select',
    iconName: '',
    widgetIsLoaded: function widgetIsLoaded() {
      return true;
    },
    isFit: function isFit(question) {
      return question.getType() === 'randomselect';
    },
    activatedByChanged: function activatedByChanged(activatedBy) {
      Survey.JsonObject.metaData.addClass("randomselect", [], null, "text");
      Survey.JsonObject.metaData.addProperty("randomselect", {
        name: "choices:itemvalues",
        category: "choices"
      });
      Survey.JsonObject.metaData.addProperty("page", {
        name: "choices:itemvalues"
      });
    },
    isDefaultRender: false,
    htmlTemplate: "<div class=\"random-select\"></div>",
    afterRender: function afterRender(question, el) {
      var choices = question.choices;
      if (choices.length) {
        var randomChoice = Math.floor(Math.random() * choices.length);
        var selectedChoice = choices[randomChoice].getData();
        question.setNewValue(selectedChoice.value);
      }
      if (!el.parentElement.parentElement.classList.contains('question_actions')) {
        el.closest('.sv-row').style = 'display: none';
      }
    }
  };
  var _default = RandomSelect;
  _exports.default = _default;
});
define("momentumhub/controllers/onboarding-new", ["exports", "momentumhub/controllers/registration", "momentumhub/config/environment"], function (_exports, _registration, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _RegistrationControll;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = _registration.default.extend((_RegistrationControll = {
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    showAgeInput: false,
    showConsentBox: false,
    first_name: '',
    last_name: '',
    user_email: '',
    password: '',
    confirm_password: '',
    isExistingUser: '',
    gender: null,
    age: null,
    isGuardianPresent: null,
    checkExistingUser: true,
    showSendGuardianEmailButton: false,
    showConsentForm: false,
    provideConsent: false,
    showOnboardingForm: false
  }, _defineProperty(_RegistrationControll, "gender", [{
    "name": "Male",
    "value": "M"
  }, {
    "name": "Female",
    "value": "F"
  }, {
    "name": "Other",
    "value": "O"
  }]), _defineProperty(_RegistrationControll, "init", function init() {
    this._super.apply(this, arguments);
  }), _defineProperty(_RegistrationControll, "checkIfStudyPublished", function checkIfStudyPublished() {
    var studyId = this.get('studyId');
    var that = this;
    $.ajax({
      url: _environment.default.APP.API_HOST + '/is-study-published/' + studyId + '/',
      success: function success(response) {
        console.log(response);
        var isPublished = response.isStudyPublishedView.is_published;
        if (isPublished) {
          if (that.session.isAuthenticated) {
            that.set('showAgeInput', false);
            that.set('showConsentBox', false);
            that.set('checkExistingUser', false);
            var userId = that.session.get('data.userId');
            var age = localStorage.getItem('participantAge');
            that.createGroupInstance(userId, age, true);
          }
        } else {
          that.set('checkExistingUser', false);
          that.toast.error('E01 - You cannot enroll in this study as it is in draft mode.', 'Study not published', {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "3000",
            "hideDuration": "1000",
            "timeOut": "30000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
          });
        }
      }
    });
  }), _defineProperty(_RegistrationControll, "actions", {
    setAge: function setAge() {
      this.set('showAgeInput', false);
      var showConsentBox = true;
      localStorage.setItem('onboardingAge', this.get('age'));
      if (this.get('age') < 7 || this.get('age') > 17) {
        this.set('showWrongAge', true);
        var redirectionUrl = _environment.default.APP.ONBOARDING_EXIT_WRONGAGE_PAGE;
        window.location.href = redirectionUrl;
      } else if (this.get('age') > 15) {
        var moduleId = _environment.default.APP.ONBOARDING_INTRO_VIDEO_YOUTH;
        this.set('showConsentForm', true);
        window.location.href = '/hedgedoc/p/' + moduleId;
      } else {
        this.set('showConsentBox', true);
      }
    },
    checkGuardianPresent: function checkGuardianPresent() {
      this.set('showConsentBox', false);
      if (this.get('isGuardianPresent')) {
        var age = this.get('age');
        var moduleId = _environment.default.APP.ONBOARDING_INTRO_VIDEO_CHILD;
        if (age > 12 && age < 16) {
          moduleId = _environment.default.APP.ONBOARDING_INTRO_VIDEO_TEEN;
        }
        this.set('showConsentForm', true);
        window.location.href = '/hedgedoc/p/' + moduleId;
      } else {
        this.set('showSendGuardianEmailButton', true);
      }
    },
    confirmExistingUser: function confirmExistingUser() {
      var existingUser = this.get('isExistingUser');
      if (existingUser === 'yes') {
        var onboardingDetails = JSON.parse(localStorage.getItem('onboardingDetails'));
        this.transitionToRoute('registration', onboardingDetails.studyId, onboardingDetails.groupId);
      } else if (existingUser === 'no') {
        this.set('checkExistingUser', false);
        this.set('showAgeInput', true);
      }
    },
    setIsGuardianPresent: function setIsGuardianPresent(value) {
      this.set('isGuardianPresent', value === 'true');
    },
    setExistingUser: function setExistingUser(value) {
      this.set('isExistingUser', value);
    },
    sendGuardianEmail: function sendGuardianEmail() {
      if (!this.get('guardianName') || !this.get('guardianEmail')) {
        return;
      }
      var onboardingDetails = JSON.parse(localStorage.getItem('onboardingDetails'));
      var data = {
        study_id: onboardingDetails.studyId,
        group_id: onboardingDetails.groupId,
        name: this.get('guardianName'),
        email: this.get('guardianEmail')
      };
      var that = this;
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studies/send-guardian-email/',
        type: 'POST',
        data: data,
        success: function success(res) {
          that.toast.success('Email sent to guardian successfully', 'Email sent', {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
          });
          var redirectionUrl = _environment.default.APP.ONBOARDING_EXIT_NO_GUARDIAN_PAGE;
          window.location.href = redirectionUrl;
          this.set('showSendGuardianEmailButton', false);
          this.set('showEmailSuccess', true);
        }
      });
    },
    lowerCaseEmail: function lowerCaseEmail() {
      var email = this.get('user_email');
      var lowercase = function lowercase(s) {
        if (typeof s !== 'string') return '';
        return s.toLowerCase();
      };
      if (email) {
        this.set('user_email', lowercase(email));
      }
    }
  }), _RegistrationControll));
  _exports.default = _default;
});
define("momentumhub/routes/inbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      },
      refreshDashboardNotifications: function refreshDashboardNotifications() {
        this.controllerFor("dashboard").getNotifications();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor("inbox").set("receivedMessage", true);
      this.controllerFor("inbox").set("sentmessage", false);
      this.controllerFor("inbox").set("writeMessage", false);
      this.controllerFor("inbox").set("readMessage", false);
      this.controllerFor("inbox").set("openedmessage", "");
      this.controllerFor("inbox").getMessages();
    }
  });
  _exports.default = _default;
});
define("momentumhub/components/survey-creator", ["exports", "momentumhub/config/environment", "jquery", "survey-creator"], function (_exports, _environment, _jquery, _surveyCreator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    surveyId: "",
    init: function init() {
      this._super.apply(this, arguments);
      Ember.$.getScript('/widget-jquery-bar-rating.js');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.loadSurveyBuilder();
    },
    /* Loads the survey Builder */
    loadSurveyBuilder: function loadSurveyBuilder(model) {
      var that = this;
      var surveyId = this.surveyId;
      var options = {
        showLogicTab: true
        // questionTypes: ["text", "checkbox"]
      };

      var surveyCreator = new _surveyCreator.default.SurveyCreator("creatorElement", options);
      _surveyCreator.default.StylesManager.applyTheme("modern");
      surveyCreator.showToolbox = "right";
      surveyCreator.showPropertyGrid = "right";
      surveyCreator.rightContainerActiveItem("toolbox");
      surveyCreator.haveCommercialLicense = true;
      this.sendAction('assignSurveyCreatorObject', surveyCreator);

      //Auto save in localstorage
      var localStorageName = "survey-" + surveyId;
      surveyCreator.isAutoSave = true;
      surveyCreator.showState = true;
      surveyCreator.saveSurveyFunc = function (saveNo, callback) {
        that.sendAction('surveyUpdated');
        window.localStorage.setItem(localStorageName, surveyCreator.text);
        callback(saveNo, true);
      };
      var defaultJSON = {
        pages: [{
          name: 'page1'
        }],
        clearInvisibleValues: "none"
      };
      surveyCreator.text = window.localStorage.getItem(localStorageName) || JSON.stringify(defaultJSON);
      setTimeout(function () {
        surveyCreator.render("surveyCreatorDivElementID");
      }, 100);

      // Ember.run.scheduleOnce('afterRender', this, function () {
      //   // load custom widgets
      //   Ember.$.getScript('/widget-jquery-bar-rating.js');
      //   // render survey
      //   surveyCreator.render("surveyCreatorDivElementID");
      // });
    }
  });
  _exports.default = _default;
});
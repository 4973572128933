define("momentumhub/routes/study-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model(param) {
      return Ember.RSVP.hash({
        study: this.store.findRecord('study', param.id)
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor("study-analytics").setupChart();
      this.controllerFor("study-analytics").getUsers();
    }
  });
  _exports.default = _default;
});
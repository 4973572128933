define("momentumhub/helpers/custom-widgets/rating", ["exports", "survey-knockout", "survey-core"], function (_exports, Survey, SurveyCore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Rating = {
    name: "ratinggauge",
    title: "Rating Gauge",
    iconName: "",
    widgetIsLoaded: function widgetIsLoaded() {
      return true;
    },
    isFit: function isFit(question) {
      return question.getType() === 'ratinggauge';
    },
    activatedByChanged: function activatedByChanged(activatedBy) {
      Survey.JsonObject.metaData.addClass("ratinggauge", [], null, "text");
      Survey.JsonObject.metaData.addProperties("ratinggauge", [{
        name: "buttonText",
        default: "Click Me"
      }]);
      Survey.JsonObject.metaData.addProperty("ratinggauge", {
        name: "ratingRange:string",
        default: 11,
        category: "general"
      });
      Survey.JsonObject.metaData.addProperty('ratinggauge', {
        name: 'gaugeType:string',
        default: 'gauge',
        category: 'general',
        choices: ['gauge', 'happiness meter']
      });
      Survey.JsonObject.metaData.addProperty("page", {
        name: "ratingTange:string",
        default: 11
      });
      SurveyCore.JsonObject.metaData.addClass("ratinggauge", [], null, "text");
      SurveyCore.JsonObject.metaData.addProperties("ratinggauge", [{
        name: "buttonText",
        default: "Click Me"
      }]);
      SurveyCore.JsonObject.metaData.addProperty("ratinggauge", {
        name: "ratingRange:string",
        default: 11,
        category: "general"
      });
      SurveyCore.JsonObject.metaData.addProperty('ratinggauge', {
        name: 'gaugeType:string',
        default: 'gauge',
        category: 'general',
        choices: ['gauge', 'happiness meter']
      });
      SurveyCore.JsonObject.metaData.addProperty("page", {
        name: "ratingTange:string",
        default: 11
      });
    },
    isDefaultRender: false,
    htmlTemplate: "<div class=\"widget-wrapper cw-gauge--wrapper\" id=\"cw-gauge--wrapper\">\n                    <div class=\"cw-gauge--reading-container\">\n                    </div>\n                    <div class=\"cw-gauge--needle\"></div>\n                  </div>",
    afterRender: function afterRender(question, el) {
      var createCustomSurvey = function createCustomSurvey() {
        var ratingRange = question.ratingRange;
        var startValue, endValue;
        if (isNaN(ratingRange)) {
          var arr = ratingRange.split('-');
          startValue = arr[1];
          endValue = arr[0];
        } else {
          startValue = ratingRange - 1;
          endValue = 0;
        }
        var finalAngle = 120;
        var _html = "";
        var childTemplate = function childTemplate(rating, theta, i) {
          return "<div class=\"cw-reading--".concat(rating, "\"\n                                                      data-rating=\"").concat(rating, "\"\n                                                      data-theta=\"").concat(theta, "\"\n                                                      style=\"transform:rotate(").concat(theta, "Deg)\"\n                                                  >\n                                                    <div style=\"transform:rotate(").concat(theta * -1, "Deg)\">").concat(i, "</div>\n                                                  </div>");
        };
        if (question.gaugeType == 'happiness meter') {
          $(el).addClass('happiness-meter');
        }
        var count = startValue - endValue + 1;
        var angleDivider = count;
        for (var i = startValue; i >= endValue; i--) {
          _html += childTemplate(count, finalAngle, i);
          finalAngle -= 270 / angleDivider;
          count--;
        }
        var readingContainer = el.querySelector('.cw-gauge--reading-container');
        readingContainer.innerHTML = _html;
        var readingArr = el.querySelectorAll('.cw-gauge--reading-container > div');
        var needle = el.querySelector('.cw-gauge--needle');
        readingArr.forEach(function (reading) {
          reading.addEventListener('click', function (e) {
            var selected = e.target.parentElement;
            document.querySelectorAll('.cw-gauge--reading-container .selected').forEach(function (a) {
              return a.classList.remove('selected');
            });
            var rating = selected.getAttribute('data-rating');
            var theta = selected.getAttribute('data-theta');
            selected.classList.add('selected');
            needle.style.transform = "rotate(" + (Number(theta) + 95) + "deg)";
            question.value = rating;
          });
        });
      };
      if (question.value) {
        setTimeout(function () {
          var domElement = $(el);
          var dataRating = question.value;
          var selectedElement = domElement.find('div[data-rating="' + dataRating + '"]');
          selectedElement.addClass('selected');
          var theta = selectedElement.attr('data-theta');
          domElement.find('.cw-gauge--needle').css({
            'transform': "rotate(" + (Number(theta) + 95) + "deg)"
          });
          // domElement.find('.cw-gauge--reading-container > div').addClass('disable-clicks');
        }, 500);
      }
      question.registerFunctionOnPropertyValueChanged("ratingRange", createCustomSurvey);
      createCustomSurvey();
    },
    willUnmount: function willUnmount(question, el) {},
    pdfQuestionType: "text"
  };
  var _default = Rating;
  _exports.default = _default;
});
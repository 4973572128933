define("momentumhub/components/left-navbar", ["exports", "jquery", "momentumhub/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    isParticipant: false,
    totalUnread: 0,
    actions: {
      //Open login component
      login: function login() {
        (0, _jquery.default)('.hover_bkgr_fricc').show();
      },
      //Logout User
      logout: function logout() {
        (0, _jquery.default)('#isLogoutClicked').click();
        var that = this;
        that.get('session').invalidate();
        that.session.set('data.user_email', "");
        that.session.set('data.viewingName', "");
        that.session.set('data.viewingId', "");
        that.session.set('data.viewingAge', "");
        that.session.set('data.viewingDark_theme', "");
        localStorage.removeItem('ldt');
        window.location.href = _environment.default.APP.API_HOST_INITIAL + '/account/logout/';
      },
      //Open Mobile Menu bar
      openMenu: function openMenu() {
        (0, _jquery.default)('.header-bar').show();
      },
      closeMenu: function closeMenu() {
        (0, _jquery.default)('.header-bar').hide();
      }
    }
  });
  _exports.default = _default;
});
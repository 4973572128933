define("momentumhub/controllers/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    user_type: "",
    init: function init() {
      this._super.apply(this, arguments);
      var isParticipant = this.session.get('data.isParticipant');
      var that = this;
      var user_type = this.session.get('data.user_type');
      that.set("user_type", user_type);
    },
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isParticipant: false,
    appController: Ember.inject.controller('application'),
    showChangeFocus: false,
    actions: {
      // Open & close mobile menu
      open_close_menu: function open_close_menu() {
        $('.assets-component').css({
          padding: '40px'
        }, 100);
        $('.assets-component').animate({
          width: '300px'
        }, 500);
        $('.close').show(100);
        $('.inner-component').show(100);
      },
      close: function close() {
        $('.inner-component').hide();
        $('.close').hide();
        $('.assets-component').css({
          padding: '0px'
        }, 400);
        $('.assets-component').animate({
          width: '0px'
        }, 400);
      },
      refreshRoute: function refreshRoute() {
        this.send('refreshCurrentRoute');
      }
    },
    getNotifications: function getNotifications() {
      var that = this;
      var recipient = this.session.get('data.userId');
      var viewingId = this.session.get('data.viewingId');
      var viewingShow_guardian_progress_messages = this.session.get('data.viewingShow_guardian_progress_messages');
      var filterQuery = {
        page: 1,
        unread: true
      };
      if (viewingId && viewingShow_guardian_progress_messages) {
        filterQuery['participant_lookup'] = viewingId;
      } else {
        filterQuery['recipient'] = recipient;
      }
      this.store.query('notification', filterQuery).then(function (notifications) {
        that.appController.set('totalUnread', notifications.meta.count);
      });
    }
  });
  _exports.default = _default;
});
define("momentumhub/routes/feelingstracker", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/utils", "d3", "momentumhub/config/environment"], function (_exports, _authenticatedRouteMixin, _utils, _d, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    showFeelingsTracker: false,
    currentUser: "",
    data: [{
      "Country": "Symptom 1",
      "Value": 10
    }, {
      "Country": "Symptom 2",
      "Value": 4
    }, {
      "Country": "Symptom 3",
      "Value": 5
    }, {
      "Country": "Symptom 4",
      "Value": 1
    }, {
      "Country": "Symptom 5",
      "Value": 8
    }, {
      "Country": "Symptom 6",
      "Value": 4
    }],
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
      var userType = this.session.get('data.user_type');
      var showFeelingsTracker = false;
      var currentUser = '';
      if (userType == 'PT' || userType == 'CL') {
        showFeelingsTracker = true;
      } else if (userType == 'PR') {
        showFeelingsTracker = this.session.data.viewingName && this.session.data.viewingShow_feelings_tracker;
      }
      if (showFeelingsTracker) {
        var userData = (0, _utils.getCurrentUser)(this.session);
        currentUser = userData.id;
      } else {
        currentUser = this.session.get('data.userId');
      }
      this.set('showFeelingsTracker', showFeelingsTracker);
      this.set('currentUser', currentUser);
    },
    model: function model(param) {
      return Ember.RSVP.hash({});
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var that = this;
      setTimeout(function () {
        that.getAnxietySurvey();
        that.getMoodSurvey();
        that.getMood();
      }, 100);
    },
    // get data for anxiety bar graph
    getAnxietySurvey: function getAnxietySurvey() {
      var that = this;
      var currentUser = this.get('currentUser');
      var data = [];
      var temp = [];
      var surveyID = _environment.default.APP.FEELING_TRACKER_ANXIETY_SURVEY;
      var variable = _environment.default.APP.FEELING_TRACKER_ANXIETY_VARIABLE;
      this.store.query('survey', {
        identifier: surveyID
      }).then(function (surveys) {
        if (surveys.length > 0) {
          var survey = surveys.get('firstObject');
          var surveyId = survey.get('id');
          var special_category_key = variable;
          that.store.query('activity', {
            survey: surveyId,
            user: currentUser,
            page_size: 13,
            // show only the last 13 results
            page: 1
          }).then(function (activities) {
            if (activities.length > 0) {
              $('#anxietygraphtext').hide();
              activities.forEach(function (activity, index) {
                var activityId = activity.get('id');
                var results = activity.get('results');
                var timestamp = activity.get('timestamp');
                var dt = new Date(timestamp);
                //dt = dt.getDate() + "/" + (dt.getMonth() + 1) + " (" + dt.getHours() + ":" + dt.getMinutes() + ")";
                var month = dt.toLocaleString('default', {
                  month: 'long'
                });
                dt = dt.getDate() + " " + month;
                //dt = index + 1;  //changed dt to just indicate the number of the survey instance
                var ref = "";
                if (that.checkOccurance(dt, temp) > 0) {
                  ref = " (" + (that.checkOccurance(dt, temp) + 1) + ")";
                }
                if (results[special_category_key]) {
                  var obj = {
                    "Name": dt + ref,
                    "Value": results[special_category_key]
                  };
                  data.push(obj);
                  temp.push(dt);
                }
              });
              that.createMyAnxietyGraph(data);
            } else {
              $('#anxietygraphtext').show();
            }
          });
        } else {
          $('#anxietygraphtext').show();
        }
      });
    },
    // get data for mood bar graph
    getMoodSurvey: function getMoodSurvey() {
      var that = this;
      var currentUser = this.get('currentUser');
      var data = [];
      var temp = [];
      var surveyID = _environment.default.APP.FEELING_TRACKER_MOOD_SURVEY;
      var variable = _environment.default.APP.FEELING_TRACKER_MOOD_VARIABLE;
      this.store.query('survey', {
        identifier: surveyID
      }).then(function (surveys) {
        if (surveys.length > 0) {
          var survey = surveys.get('firstObject');
          var surveyId = survey.get('id');
          var special_category_key = variable;
          that.store.query('activity', {
            survey: surveyId,
            user: currentUser,
            page: 1,
            page_size: 13 // show only last 13 results
          }).then(function (activities) {
            if (activities.length > 0) {
              $('#moodgraphtext').hide();
              activities.forEach(function (activity, index) {
                var activityId = activity.get('id');
                var results = activity.get('results');
                var timestamp = activity.get('timestamp');
                var dt = new Date(timestamp);
                //dt = dt.getDate() + "/" + (dt.getMonth() + 1) + " (" + dt.getHours() + ":" + dt.getMinutes() + ")";
                var month = dt.toLocaleString('default', {
                  month: 'long'
                });
                dt = dt.getDate() + " " + month;
                //dt = index + 1;  //changed dt to just indicate the number of the survey instance
                var ref = "";
                if (that.checkOccurance(dt, temp) > 0) {
                  ref = " (" + (that.checkOccurance(dt, temp) + 1) + ")";
                }
                if (results[special_category_key]) {
                  var obj = {
                    "Name": dt + ref,
                    "Value": results[special_category_key]
                  };
                  data.push(obj);
                  temp.push(dt);
                }
              });
              that.createMyMoodGraph(data);
            } else {
              $('#moodgraphtext').show();
            }
          });
        } else {
          $('#moodgraphtext').show();
        }
      });
    },
    createMyAnxietyGraph: function createMyAnxietyGraph(data) {
      var colorRange = _environment.default.APP.FEELING_TRACKER_ANXIETY_COLOR;
      colorRange = JSON.parse(colorRange);
      // set the dimensions and margins of the graph
      var outerDivWidth = $('#anxiety_box').width();
      var margin = {
          top: 10,
          right: 30,
          bottom: 90,
          left: 40
        },
        width = outerDivWidth - margin.left - margin.right,
        height = 380 - margin.top - margin.bottom;
      var maxBarHeight = this.getMaxNumber(data);
      var bar_width = 20;
      var mq = window.matchMedia("(min-width: 1500px)");
      if (mq.matches) {
        bar_width = 30;
      }
      // append the svg object to the body of the page
      var svg = _d.default.select("#my_dataviz").append("svg").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // X axis - note we are hiding the ticks using CSS: `#my_dataviz line`
      var x = _d.default.scalePoint()
      //.range([0, width])
      .range([width, 0]) // invert order of bars (old to new)
      .domain(data.map(function (d) {
        return d.Name;
      })).padding(0.3);
      svg.append("g").attr("transform", "translate(0," + height + ")") //moves axis to bottom
      .call(_d.default.axisBottom(x)).selectAll("text").style("font-size", "15px").attr("transform", "translate(-10,13)rotate(-45)"); // transform label text
      //.style("text-anchor", "end");
      //.attr("x", function (d) { return x(d.Name);
      svg.append("text").attr("class", "x label").attr("text-anchor", "start").attr("x", -41).attr("y", height + 18).text("Date");

      // Add Y axis
      var y = _d.default.scaleLinear().domain([0, maxBarHeight]).range([height, 0]);
      //svg.append("g")
      //    .call(d3.axisLeft(y));

      // Bars
      svg.selectAll("mybar").data(data).enter().append("rect").attr("x", function (d) {
        return x(d.Name);
      })
      // .attr("width", x.bandwidth())
      .attr("width", bar_width).attr("fill", "#69b3a2")
      // no bar at the beginning thus:
      .attr("height", function (d) {
        return height - y(0);
      }) // always equal to 0
      .attr("y", function (d) {
        return y(0);
      }).attr('rx', 8); // add rounded corners

      // Animation
      svg.selectAll("rect").transition().duration(800).attr("y", function (d) {
        return y(d.Value);
      }).attr("height", function (d) {
        return height - y(d.Value);
      }).delay(function (d, i) {
        console.log(i);
        return i * 100;
      }).style("fill", function (d) {
        if (colorRange[0]) {
          if (d.Value >= colorRange[0].from && d.Value <= colorRange[0].to) {
            return colorRange[0].color;
          }
        }
        if (colorRange[1]) {
          if (d.Value >= colorRange[1].from && d.Value <= colorRange[1].to) {
            return colorRange[1].color;
          }
        }
        if (colorRange[2]) {
          if (d.Value >= colorRange[2].from && d.Value <= colorRange[2].to) {
            return colorRange[2].color;
          }
        }
        if (colorRange[3]) {
          if (d.Value >= colorRange[3].from && d.Value <= colorRange[3].to) {
            return colorRange[3].color;
          }
        }
        if (colorRange[4]) {
          if (d.Value >= colorRange[4].from && d.Value <= colorRange[4].to) {
            return colorRange[4].color;
          }
        }
        if (colorRange[5]) {
          if (d.Value >= colorRange[5].from && d.Value <= colorRange[5].to) {
            return colorRange[5].color;
          }
        }
      });
    },
    createMyMoodGraph: function createMyMoodGraph(data) {
      var colorRange = _environment.default.APP.FEELING_TRACKER_MOOD_COLOR;
      colorRange = JSON.parse(colorRange);
      // set the dimensions and margins of the graph
      var outerDivWidth = $('#anxiety_box1').width();
      var bar_width = 20;
      var mq = window.matchMedia("(min-width: 1500px)");
      if (mq.matches) {
        bar_width = 30;
      }
      var margin = {
          top: 10,
          right: 30,
          bottom: 90,
          left: 40
        },
        width = outerDivWidth - margin.left - margin.right,
        height = 380 - margin.top - margin.bottom;
      var maxBarHeight = this.getMaxNumber(data);
      // append the svg object to the body of the page
      var svg = _d.default.select("#my_dataviz1").append("svg").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // X axis - note we are hiding the ticks using CSS: `#my_dataviz line`
      var x = _d.default.scalePoint()
      //.range([0, width])
      .range([width, 0]) // invert order of bars (old to new)
      .domain(data.map(function (d) {
        return d.Name;
      })).padding(0.3);
      svg.append("g").attr("transform", "translate(0," + height + ")") //moves axis to bottom
      .call(_d.default.axisBottom(x)).selectAll("text").style("font-size", "15px").attr("transform", "translate(-10,13)rotate(-45)"); // transform label text
      //.style("text-anchor", "end");
      //.attr("x", function (d) { return x(d.Name);
      svg.append("text").attr("class", "x label").attr("text-anchor", "start").attr("x", -41).attr("y", height + 18).text("Date");

      // Add Y axis
      var y = _d.default.scaleLinear().domain([0, maxBarHeight]).range([height, 0]);
      //svg.append("g")
      //    .call(d3.axisLeft(y));

      // Bars
      svg.selectAll("mybar").data(data).enter().append("rect").attr("x", function (d) {
        return x(d.Name);
      })
      // .attr("width", x.bandwidth())
      .attr("width", bar_width).attr("fill", "#69b3a2")
      // no bar at the beginning thus:
      .attr("height", function (d) {
        return height - y(0);
      }) // always equal to 0
      .attr("y", function (d) {
        return y(0);
      }).attr('rx', 8); // add rounded corners

      // Animation
      svg.selectAll("rect").transition().duration(800).attr("y", function (d) {
        return y(d.Value);
      }).attr("height", function (d) {
        return height - y(d.Value);
      }).delay(function (d, i) {
        console.log(i);
        return i * 100;
      }).style("fill", function (d) {
        if (colorRange[0]) {
          if (d.Value >= colorRange[0].from && d.Value <= colorRange[0].to) {
            return colorRange[0].color;
          }
        }
        if (colorRange[1]) {
          if (d.Value >= colorRange[1].from && d.Value <= colorRange[1].to) {
            return colorRange[1].color;
          }
        }
        if (colorRange[2]) {
          if (d.Value >= colorRange[2].from && d.Value <= colorRange[2].to) {
            return colorRange[2].color;
          }
        }
        if (colorRange[3]) {
          if (d.Value >= colorRange[3].from && d.Value <= colorRange[3].to) {
            return colorRange[3].color;
          }
        }
        if (colorRange[4]) {
          if (d.Value >= colorRange[4].from && d.Value <= colorRange[4].to) {
            return colorRange[4].color;
          }
        }
        if (colorRange[5]) {
          if (d.Value >= colorRange[5].from && d.Value <= colorRange[5].to) {
            return colorRange[5].color;
          }
        }
      });
    },
    getMood: function getMood() {
      var that = this;
      var moodArray = [];
      var currentUser = this.get('currentUser');

      // Tomorrows date
      var currentDate = new Date();
      var todayYear = currentDate.getFullYear();
      var todayMonth = currentDate.getMonth() + 1;
      var todayDate = currentDate.getDate() + 1;
      if (todayDate < 10) {
        todayDate = '0' + todayDate;
      }
      var todayStringFormat = todayYear + '-' + todayMonth + '-' + todayDate;

      // Last 30 days
      var lastThirtyDays = new Date(new Date().setDate(new Date().getDate() - 30));
      var lastThirtyDaysStringFormatYear = lastThirtyDays.getFullYear();
      var lastThirtyDaysStringFormatMonth = lastThirtyDays.getMonth() + 1;
      var lastThirtyDaysStringFormatDate = lastThirtyDays.getDate();
      if (lastThirtyDaysStringFormatDate < 10) {
        lastThirtyDaysStringFormatDate = '0' + lastThirtyDaysStringFormatDate;
      }
      var lastThirtyDaysStringFormat = lastThirtyDaysStringFormatYear + '-' + lastThirtyDaysStringFormatMonth + '-' + lastThirtyDaysStringFormatDate;
      this.store.query('mood', {
        created_at_lte: todayStringFormat,
        created_at_gte: lastThirtyDaysStringFormat,
        user: currentUser
      }).then(function (moods) {
        moods.forEach(function (a) {
          var mood_type = a.get('mood_type');
          //Happy
          if (mood_type == "HA") {
            moodArray.push('/assets/participantDashboard/all_dashboards/Shared/faces/Happy.svg');
          }
          //Doubt
          if (mood_type == "DB") {
            moodArray.push('/assets/participantDashboard/all_dashboards/Shared/faces/Unsure.svg');
          }
          //Angry
          if (mood_type == "AN") {
            moodArray.push('/assets/participantDashboard/all_dashboards/Shared/faces/Angry.svg');
          }
          //Sleep
          if (mood_type == "SL") {
            moodArray.push('/assets/participantDashboard/all_dashboards/Shared/faces/Tired.svg');
          }
          // Anxious
          if (mood_type == "AX") {
            moodArray.push('/assets/participantDashboard/all_dashboards/Shared/faces/Meh.svg');
          }
          // Sad
          if (mood_type == "SD") {
            moodArray.push('/assets/participantDashboard/all_dashboards/Shared/faces/Sad.svg');
          }
          // Scary
          if (mood_type == "SC") {
            moodArray.push('/assets/participantDashboard/all_dashboards/Shared/faces/Worried.svg');
          }
        });
        that.createpictureCloud(moodArray);
      });
    },
    createpictureCloud: function createpictureCloud(moodArray) {
      // specify svg width and height;
      var width = $('#feelingouter').width(),
        height = $('#feelingouter').height();
      var listenTo = Math.min(width, height);
      // create svg and g DOM elements;
      var svg = _d.default.select('#feelingtracker').append('svg').attr('xmlns', 'http://www.w3.org/2000/svg').attr('width', width).attr('height', height).append('g')
      // move 0,0 to the center
      .attr('transform', "translate(".concat(width >> 1, ", ").concat(height >> 1, ")"));
      var images = [],
        maxImages = 30,
        maxWeight = 100,
        minWeight = 1,
        padding = 15;
      for (var i = 0; i < moodArray.length - 1; i++) {
        var weight = Math.random() * (maxWeight - minWeight) + minWeight;
        images.push({
          url: moodArray[i],
          weight: weight
        });
      }
      // make one image with a weight 3 times bigger for visualization testing propouses
      images.push({
        url: moodArray[moodArray.length - 1],
        weight: maxWeight * 3,
        fx: 0,
        fy: 0
      });
      images.sort(function (a, b) {
        return b.weight - a.weight;
      });

      // make it so the biggest images is equal to 10% of canvas, and the smallest one 1%
      // getting some images out of bounds, probably due to "blowup" here...
      var scl = 100 / maxImages / 50;
      // add scaling factor to make easier to increase image sizes
      var blowup = 1.3; // use this to "blowup" images!
      var maxImageSize = listenTo * 0.2 * blowup;
      var minImageSize = listenTo * scl * blowup;

      // function to scale the images
      var scaleSize = _d.default.scaleLinear().domain([minWeight, maxWeight * 3]).range([minImageSize, maxImageSize]).clamp(true);

      // append the rects
      var vizImages = svg.selectAll('.image-cloud-image').data(images).enter().append('svg:image').attr('class', '.image-cloud-image').attr('height', function (d) {
        return scaleSize(d.weight * 2);
      }).attr('width', function (d) {
        return scaleSize(d.weight * 2);
      }).attr('id', function (d) {
        return d.url;
      }).attr('xlink:href', function (d) {
        return d.url;
      });
      vizImages.exit().remove();

      // create the collection of forces
      var simulation = _d.default.forceSimulation()
      // set the nodes for the simulation to be our images
      .nodes(images)
      // set the function that will update the view on each 'tick'
      .on('tick', ticked).force('center', _d.default.forceCenter()).force('cramp', _d.default.forceManyBody().strength(listenTo / 100))
      // collition force for rects
      .force('collide', rectCollide().size(function (d) {
        var s = scaleSize(d.weight);
        return [s + padding, s + padding];
      }));

      // update the position to new x and y
      function ticked() {
        vizImages.attr('x', function (d) {
          return d.x;
        }).attr('y', function (d) {
          return d.y;
        });
      }
      function rectCollide() {
        var nodes, sizes, masses;
        var size = constant([0, 0]);
        var strength = 1;
        var iterations = 1;
        function force() {
          var node, size, mass, xi, yi;
          var i = -1;
          while (++i < iterations) {
            iterate();
          }
          function iterate() {
            var j = -1;
            var tree = _d.default.quadtree(nodes, xCenter, yCenter).visitAfter(prepare);
            while (++j < nodes.length) {
              node = nodes[j];
              size = sizes[j];
              mass = masses[j];
              xi = xCenter(node);
              yi = yCenter(node);
              tree.visit(apply);
            }
          }
          function apply(quad, x0, y0, x1, y1) {
            var data = quad.data;
            var xSize = (size[0] + quad.size[0]) / 2;
            var ySize = (size[1] + quad.size[1]) / 2;
            if (data) {
              if (data.index <= node.index) {
                return;
              }
              var x = xi - xCenter(data);
              var y = yi - yCenter(data);
              var xd = Math.abs(x) - xSize;
              var yd = Math.abs(y) - ySize;
              if (xd < 0 && yd < 0) {
                var l = Math.sqrt(x * x + y * y);
                var m = masses[data.index] / (mass + masses[data.index]);
                if (Math.abs(xd) < Math.abs(yd)) {
                  node.vx -= (x *= xd / l * strength) * m;
                  data.vx += x * (1 - m);
                } else {
                  node.vy -= (y *= yd / l * strength) * m;
                  data.vy += y * (1 - m);
                }
              }
            }
            return x0 > xi + xSize || y0 > yi + ySize || x1 < xi - xSize || y1 < yi - ySize;
          }
          function prepare(quad) {
            if (quad.data) {
              quad.size = sizes[quad.data.index];
            } else {
              quad.size = [0, 0];
              var i = -1;
              while (++i < 4) {
                if (quad[i] && quad[i].size) {
                  quad.size[0] = Math.max(quad.size[0], quad[i].size[0]);
                  quad.size[1] = Math.max(quad.size[1], quad[i].size[1]);
                }
              }
            }
          }
        }
        function xCenter(d) {
          return d.x + d.vx + sizes[d.index][0] / 2;
        }
        function yCenter(d) {
          return d.y + d.vy + sizes[d.index][1] / 2;
        }
        force.initialize = function (_) {
          sizes = (nodes = _).map(size);
          masses = sizes.map(function (d) {
            return d[0] * d[1];
          });
        };
        force.size = function (_) {
          return arguments.length ? (size = typeof _ === 'function' ? _ : constant(_), force) : size;
        };
        force.strength = function (_) {
          return arguments.length ? (strength = +_, force) : strength;
        };
        force.iterations = function (_) {
          return arguments.length ? (iterations = +_, force) : iterations;
        };
        return force;
      }
      function constant(_) {
        return function () {
          return _;
        };
      }
    },
    checkOccurance: function checkOccurance(text, array) {
      var count = 0;
      array.forEach(function (a) {
        if (a == text) {
          count = count + 1;
        }
      });
      return count;
    },
    getMaxNumber: function getMaxNumber(array) {
      var temp = [];
      array.forEach(function (a) {
        temp.push(a.Value);
      });
      return Math.max.apply(null, temp);
    }
  });
  _exports.default = _default;
});
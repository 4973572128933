define("momentumhub/components/assets-component", ["exports", "jquery", "momentumhub/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MEDIA_OBJ = {
    "Media": {
      left: "20%",
      name: "MD"
    },
    "Survey": {
      left: "40%",
      name: "SV"
    },
    "Embedded": {
      left: "60%",
      name: "EMBEDDED"
    }
  };
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    toast: Ember.inject.service(),
    uploadFile: false,
    assets: [],
    embeddeds: [],
    mediaType: "IM",
    mediaPreview: "",
    zipFile: "",
    delaySurveyTimer: "",
    delayEmbeddedTimer: "",
    chooseAssetMedia: "MD",
    isVideo: false,
    vimeo_access_token: "",
    searchasset: "",
    images: [],
    svgs: [],
    audio: [],
    videos: [],
    docs: [],
    imagePage: 1,
    audioPage: 1,
    svgPage: 1,
    dcPage: 1,
    nextImages: "",
    nextAudio: "",
    nextSvg: "",
    nextDoc: "",
    nextSurveys: "",
    surveyPage: 1,
    hoverShadow: "20%",
    page_size: 15,
    nextEmbeddeds: "",
    embeddedsPage: 1,
    //Surveys
    surveys: [],
    init: function init() {
      this._super.apply(this, arguments);
      this.set('images', []);
      this.set('svgs', []);
      this.set('audio', []);
      this.set('videos', []);
      this.set('docs', []);
      this.send('getImages');
      this.authenticateVimeo();
      this.send('getSurveys');
      this.send('getEmbedded');
    },
    //Action
    actions: {
      refreshAssets: function refreshAssets() {
        var mediaType = this.mediaType;
        (0, _jquery.default)('.refreshModulesurvey').addClass('bounce');
        setTimeout(function () {
          (0, _jquery.default)('.refreshModulesurvey').removeClass('bounce');
        }, 1000);
        this.set('imagePage', 1);
        this.set('audioPage', 1);
        this.set('svgPage', 1);
        this.set('dcPage', 1);
        if (mediaType == "IM") {
          this.set('images', []);
          this.send('getImages');
        }
        if (mediaType == "SV") {
          this.set('svgs', []);
          this.send('getSvgs');
        }
        if (mediaType == "AD") {
          this.set('audio', []);
          this.send('getAudio');
        }
        if (mediaType == "DC") {
          this.set('docs', []);
          this.send('getdocs');
        }
      },
      refreshSurveys: function refreshSurveys() {
        (0, _jquery.default)('.refreshModulesurvey').addClass('bounce');
        setTimeout(function () {
          (0, _jquery.default)('.refreshModulesurvey').removeClass('bounce');
        }, 1000);
        this.set('searchasset', "");
        this.send('getSurveys');
      },
      //Close assets component
      close: function close() {
        (0, _jquery.default)('.close').hide();
        (0, _jquery.default)('.inner-component').hide();
        (0, _jquery.default)('.assets-component').css({
          padding: '0px'
        }, 300);
        (0, _jquery.default)('.assets-component').animate({
          width: '0px'
        }, 300);
        this.set('uploadFile', false);
        (0, _jquery.default)('.create-module-right').animate({
          width: '0px'
        }, 300);
        (0, _jquery.default)('.create-module-center').animate({
          width: '100%'
        }, 300);
        (0, _jquery.default)('#open-assets-button').show();
      },
      //Choose media
      chooseMedia: function chooseMedia(mediatype) {
        if (mediatype === "photos") {
          (0, _jquery.default)('.media-choices').css({
            "color": 'black'
          });
          (0, _jquery.default)('.shadow').animate({
            left: '0px'
          });
          (0, _jquery.default)('.photos').css({
            "color": 'white'
          });
          this.set('mediaType', "IM");
          this.set('isVideo', false);
          this.send('getImages');
        } else if (mediatype === "videos") {
          (0, _jquery.default)('.media-choices').css({
            "color": 'black'
          });
          (0, _jquery.default)('.shadow').animate({
            left: '20%'
          });
          (0, _jquery.default)('.videos').css({
            "color": 'white'
          });
          this.set('mediaType', "VD");
          this.set('isVideo', true);
          this.set('assets', []);
          // this.authenticateVimeo();
        } else if (mediatype === "music") {
          (0, _jquery.default)('.media-choices').css({
            "color": 'black'
          });
          (0, _jquery.default)('.shadow').animate({
            left: '40%'
          });
          (0, _jquery.default)('.music').css({
            "color": 'white'
          });
          this.set('mediaType', "AD");
          this.set('isVideo', false);
          this.send('getAudio');
        } else if (mediatype === "file") {
          (0, _jquery.default)('.media-choices').css({
            "color": 'black'
          });
          (0, _jquery.default)('.shadow').animate({
            left: '60%'
          });
          (0, _jquery.default)('.file').css({
            "color": 'white'
          });
          this.set('mediaType', "DC");
          this.set('isVideo', false);
          this.send('getdocs');
        } else {
          (0, _jquery.default)('.svgtypeerror').hide();
          (0, _jquery.default)('.media-choices').css({
            "color": 'black'
          });
          (0, _jquery.default)('.shadow').animate({
            left: '80%'
          });
          (0, _jquery.default)('.svg').css({
            "color": 'white'
          });
          this.set('mediaType', "SV");
          this.set('isVideo', false);
          this.send('getSvgs');
        }
      },
      // plusSign action
      onPlusClick: function onPlusClick() {
        if (this.chooseAssetMedia === 'SV') {
          this.send('routeToSurveys');
        } else {
          this.send('uploadFileClicked');
        }
      },
      // Upload file clicked
      uploadFileClicked: function uploadFileClicked() {
        var uploadFile = this.get('uploadFile');
        if (uploadFile) {
          this.set('uploadFile', false);
        } else {
          this.set('uploadFile', true);
        }
      },
      onclickAssetDelete: function onclickAssetDelete(id) {
        var record = this.store.peekRecord(id);
        record.destroyRecord();
      },
      getImages: function getImages() {
        var that = this;
        that.set('images', []);
        var searchasset = this.get('searchasset');
        this.store.query('asset', {
          category: "IM",
          page: that.imagePage,
          name__icontains: searchasset,
          page_size: that.page_size
        }).then(function (assets) {
          assets.forEach(function (element) {
            that.images.pushObject(element);
          });
          var next = assets.meta.next;
          that.set('nextImages', next);
        });
      },
      getAudio: function getAudio() {
        var that = this;
        that.set('audio', []);
        var searchasset = this.get('searchasset');
        //Music
        this.store.query('asset', {
          category: "AD",
          page: that.audioPage,
          name__icontains: searchasset,
          page_size: that.page_size
        }).then(function (assets) {
          assets.forEach(function (element) {
            that.audio.pushObject(element);
          });
          var next = assets.meta.next;
          that.set('nextAudio', next);
        });
      },
      getSvgs: function getSvgs() {
        var that = this;
        that.set('svgs', []);
        var searchasset = this.get('searchasset');
        this.store.query('asset', {
          category: "SV",
          page: that.svgPage,
          name__icontains: searchasset,
          page_size: that.page_size
        }).then(function (assets) {
          assets.forEach(function (element) {
            that.svgs.pushObject(element);
          });
          var next = assets.meta.next;
          that.set('nextSvg', next);
        });
      },
      getdocs: function getdocs() {
        var that = this;
        that.set('docs', []);
        var searchasset = this.get('searchasset');
        this.store.query('asset', {
          category: "DC",
          page: that.dcPage,
          name__icontains: searchasset,
          page_size: that.page_size
        }).then(function (assets) {
          assets.forEach(function (element) {
            that.docs.pushObject(element);
          });
          var next = assets.meta.next;
          that.set('nextDoc', next);
        });
      },
      loadMoreAssets: function loadMoreAssets() {
        var mediaType = this.mediaType;
        if (mediaType == "IM") {
          this.imagePage = this.imagePage + 1;
          this.send('getImages');
        }
        if (mediaType == "SV") {
          this.svgPage = this.svgPage + 1;
          this.send('getSvgs');
        }
        if (mediaType == "AD") {
          this.audioPage = this.audioPage + 1;
          this.send('getAudio');
        }
        if (mediaType == "DC") {
          this.audioPage = this.dcPage + 1;
          this.send('getdocs');
        }
      },
      /* Search Assets */
      searchAssetByName: function searchAssetByName() {
        var that = this;
        var searchasset = this.get('searchasset');
        var mediaType = this.get('mediaType');
        if (mediaType === "VD") {
          clearTimeout(this.delayTimer);
          that.delayTimer = setTimeout(function () {
            if (searchasset) {
              that.authenticateVimeo();
            } else {
              that.authenticateVimeo();
            }
            (0, _jquery.default)('.assets-VD').show();
          }, 1000);
        }
        if (mediaType == "IM") {
          clearTimeout(this.delayTimer);
          that.delayTimer = setTimeout(function () {
            that.set('images', []);
            that.imagePage = 1;
            that.send('getImages');
            setTimeout(function () {
              (0, _jquery.default)('.assets-IM').show();
            }, 100);
          }, 1000);
        }
        if (mediaType == "SV") {
          clearTimeout(this.delayTimer);
          that.delayTimer = setTimeout(function () {
            that.set('svgs', []);
            that.svgPage = 1;
            that.send('getSvgs');
            setTimeout(function () {
              (0, _jquery.default)('.assets-SV').show();
            }, 100);
          }, 1000);
        }
        if (mediaType == "AD") {
          clearTimeout(this.delayTimer);
          that.delayTimer = setTimeout(function () {
            that.set('audio', []);
            that.audioPage = 1;
            that.send('getAudio');
            setTimeout(function () {
              (0, _jquery.default)('.assets-AD').show();
            }, 100);
          }, 1000);
        }
        if (mediaType == "DC") {
          clearTimeout(this.delayTimer);
          that.delayTimer = setTimeout(function () {
            that.set('docs', []);
            that.dcPage = 1;
            that.send('getdocs');
            setTimeout(function () {
              (0, _jquery.default)('.assets-DC').show();
            }, 100);
          }, 1000);
        }
      },
      /* Preview the media */
      previewMedia: function previewMedia() {
        var uploadmediainput = document.getElementById('uploadmediainput');
        var fileName = uploadmediainput.files[0].name;
        var fileSize = uploadmediainput.files[0].size;
        console.log(fileSize);
        this.set('mediaUploadError', '');
        if (fileSize > 1024000) {
          this.set('mediaUploadError', 'Maximum allowed file size is 1MB. Please compress your file');
          uploadmediainput.value = null;
          return;
        } else if (fileSize > 512000) {
          this.set('mediaUploadError', 'Your file size is greater than 512KB. Maximum allowed file size is 1MB');
        }
        (0, _jquery.default)('#file_name').append(fileName);
        var temp = fileName.split('.');
        document.getElementById('mediaName').value = temp[0];
        var tmppath = URL.createObjectURL(uploadmediainput.files[0]);
        this.set('mediaPreview', tmppath);
      },
      previewZip: function previewZip() {
        var uploadmediainput = document.getElementById('uplaodZipFile');
        var tmppath = URL.createObjectURL(uploadmediainput.files[0]);
        this.set('zipFile', tmppath);
      },
      /* Back to media gallary */
      backToGallary: function backToGallary() {
        this.set('uploadFile', false);
      },
      /* Upload Assets to the backend */
      uploadMedia: function uploadMedia() {
        var requiredFields, mediaName, mediaFile, mediaType, formdata, url, zipFile;
        var validSvg, validPdf, validImage, validEmbeded;
        mediaName = document.getElementById('mediaName').value;
        mediaFile = document.getElementById('uploadmediainput');
        zipFile = document.getElementById('uplaodZipFile');
        var that = this;
        var token = that.get('session.data.authenticated.access_token');
        mediaType = this.get('mediaType');
        if (this.chooseAssetMedia === 'EMBEDDED') {
          validSvg = true;
          validPdf = true;
          validImage = true;
          validEmbeded = true;
          url = "".concat(_environment.default.APP.API_HOST, "/embeddeds");
          formdata = new FormData();
          formdata.append("name", mediaName);
          if (mediaFile && mediaFile.files && mediaFile.files.length > 0) formdata.append("thumbnail", mediaFile.files[0], mediaFile.files[0].name);
          formdata.append("source", zipFile.files[0], zipFile.files[0].name);
          requiredFields = true;
          if (zipFile.files[0]) {
            var fileName = zipFile.files[0].name;
            var fileType = fileName.split('.');
            fileType = fileType[fileType.length - 1];
            if (fileType == "zip") {
              validEmbeded = true;
            } else {
              validEmbeded = false;
            }
          }
        } else {
          url = _environment.default.APP.API_HOST + '/assets';
          formdata = new FormData();
          formdata.append("name", mediaName);
          formdata.append("category", mediaType);
          formdata.append("file_upload", mediaFile.files[0], mediaFile.files[0].name);
          requiredFields = mediaName && mediaFile.files[0] && mediaType;
          if (mediaFile.files[0]) {
            var fileName = mediaFile.files[0].name;
            var fileType = fileName.split('.');
            fileType = fileType[fileType.length - 1];
            validSvg = true;
            validPdf = true;
            validImage = true;
            validEmbeded = true;
            if (mediaType == "IM") {
              if (fileType == "png" || fileType == "jpg" || fileType == "gif" || fileType == "jpeg") {
                validImage = true;
              } else {
                validImage = false;
              }
            }
            if (mediaType == "DC") {
              if (fileType == "pdf") {
                validPdf = true;
              } else {
                validPdf = false;
              }
            }
            if (mediaType == "SV") {
              if (fileType == "svg") {
                validSvg = true;
              } else {
                validSvg = false;
              }
            }
          }
        }
        if (requiredFields) {
          if (validSvg) {
            if (validPdf) {
              if (validImage) {
                if (validEmbeded) {
                  (0, _jquery.default)('.svgtypeerror').hide();
                  (0, _jquery.default)('.svgtypeerror1').hide();
                  (0, _jquery.default)('.svgtypeerror2').hide();
                  (0, _jquery.default)('.svgtypeerror3').hide();
                  (0, _jquery.default)('.up2').show();
                  _jquery.default.ajax({
                    url: url,
                    headers: {
                      Authorization: 'Bearer ' + token
                    },
                    data: formdata,
                    processData: false,
                    contentType: false,
                    type: 'POST',
                    success: function success(res) {
                      that.set('uploadFile', false);
                      that.set('mediaPreview', "");
                      if (mediaType == "IM") {
                        that.set('images', []);
                        that.send('getImages');
                      }
                      if (mediaType == "SV") {
                        that.set('svgs', []);
                        that.send('getSvgs');
                      }
                      if (mediaType == "AD") {
                        that.set('audio', []);
                        that.send('getAudio');
                      }
                      if (mediaType == "DC") {
                        that.set('docs', []);
                        that.send('getdocs');
                      }
                      that.send('getEmbedded');
                      // that.send('getSurveys');
                    },

                    error: function error(err, xH) {
                      (0, _jquery.default)('.up2').hide();
                      that.newAlert(err.responseText, "Upload file", "Failure");
                    }
                  });
                } else {
                  (0, _jquery.default)('.svgtypeerror3').show();
                }
              } else {
                (0, _jquery.default)('.svgtypeerror2').show();
              }
            } else {
              (0, _jquery.default)('.svgtypeerror1').show();
            }
          } else {
            (0, _jquery.default)('.svgtypeerror').show();
          }
        } else {
          this.newAlert("All fields are required.", "Upload file", "Warning");
        }
      },
      /* Choose survey or media */
      chooseSurveyMedia: function chooseSurveyMedia(mediatype) {
        this.set('hoverShadow', MEDIA_OBJ[mediatype]['left']);
        this.set('chooseAssetMedia', MEDIA_OBJ[mediatype]['name']);
        this.set('mediaType', 'IM');
      },
      getSurveys: function getSurveys() {
        var nextLink = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var searchasset = this.get('searchasset');
        var that = this;
        clearTimeout(this.delaySurveyTimer);
        console.log("Next Link");
        console.log(nextLink);
        that.delaySurveyTimer = setTimeout(function () {
          var conditions = {
            test: false
          };
          if (!nextLink || nextLink === '') {
            conditions.page = 1;
          } else {
            that.set('surveyPage', that.get('surveyPage') + 1);
            conditions.page = that.get('surveyPage');
          }
          if (searchasset) {
            conditions.id_or_name = searchasset;
          }
          that.store.query('survey', conditions).then(function (surveys) {
            var surveysList = that.get('surveys');
            if (nextLink) {
              surveys.toArray().map(function (survey) {
                surveysList.pushObject(survey);
              });
            } else {
              surveysList = surveys.toArray();
            }
            that.set('surveys', surveysList);
            console.log(surveys);
            if (surveys.meta && surveys.meta.next) {
              that.set('nextSurveys', surveys.meta.next);
            } else {
              that.set('nextSurveys', null);
            }
          });
        }, 1000);
      },
      getEmbedded: function getEmbedded(nextLink) {
        var searchasset = this.get('searchasset');
        var that = this;
        clearTimeout(this.delayEmbeddedTimer);
        that.delayEmbeddedTimer = setTimeout(function () {
          var conditions = {
            test: false
          };
          if (!nextLink || nextLink === '') {
            conditions.page = 1;
          } else {
            that.set('embeddedsPage', that.get('embeddedsPage') + 1);
            conditions.page = that.get('embeddedsPage');
          }
          if (searchasset) {
            conditions.id_or_name = searchasset;
          }
          that.store.query('embedded', conditions).then(function (embeddeds) {
            var embeddedsList = that.get('embeddeds');
            if (nextLink) {
              embeddeds.toArray().map(function (embedded) {
                embeddedsList.pushObject(embedded);
              });
            } else {
              embeddedsList = embeddeds.toArray();
            }
            that.set('embeddeds', embeddedsList);
            console.log(embeddeds);
            if (embeddeds.meta && embeddeds.meta.next) {
              that.set('nextEmbeddeds', embeddeds.meta.next);
            } else {
              that.set('nextEmbeddeds', null);
            }
          });
        }, 1000);
      },
      routeToSurveys: function routeToSurveys() {
        this.get('router').transitionTo('surveys');
      },
      openEditAssetBox: function openEditAssetBox(id) {
        (0, _jquery.default)('#edit-asset' + id).slideDown(300);
      },
      closeEditAssetBox: function closeEditAssetBox(id) {
        (0, _jquery.default)('#edit-asset' + id).slideUp(300);
      },
      openEditEmbeddBox: function openEditEmbeddBox(id) {
        (0, _jquery.default)('#edit-embedded' + id).slideDown(300);
      },
      closeEditEmbeddBox: function closeEditEmbeddBox(id) {
        (0, _jquery.default)('#edit-embedded' + id).slideUp(300);
      },
      updateEmbeddedName: function updateEmbeddedName(id) {
        var name = (0, _jquery.default)("#editEmbedInp".concat(id)).val();
        var record = this.store.peekRecord('embedded', id);
        record.set('name', name);
        record.save();
        this.send('closeEditEmbeddBox', id);
      },
      deleteEmbedded: function deleteEmbedded(id) {
        var r = confirm("Are you sure you want to delete this Embedded page?");
        if (r) {
          var record = this.store.peekRecord('embedded', id);
          console.log(record);
          record.destroyRecord();
          this.send('closeEditEmbeddBox', id);
        }
      },
      updateMediaName: function updateMediaName(id) {
        var that = this;
        var mediaType = this.mediaType;
        var token = that.get('session.data.authenticated.access_token');
        var media_name = document.getElementById('eachassetinput' + id).value;
        var r = confirm("Are you sure you want to edit this asset's name?");
        if (r) {
          if (media_name) {
            var media = {
              "name": media_name
            };
            _jquery.default.ajax({
              url: _environment.default.APP.API_HOST + '/assets/' + id,
              headers: {
                Authorization: 'Bearer ' + token
              },
              data: media,
              type: 'PATCH',
              success: function success(res) {
                that.set('uploadFile', false);
                that.set('mediaPreview', "");
                (0, _jquery.default)('#edit-asset' + id).slideUp(1000);
                if (mediaType == "IM") {
                  that.set('images', []);
                  that.send('getImages');
                }
                if (mediaType == "SV") {
                  that.set('svgs', []);
                  that.send('getSvgs');
                }
                if (mediaType == "AD") {
                  that.set('audio', []);
                  that.send('getAudio');
                }
                if (mediaType == "DC") {
                  that.set('docs', []);
                  that.send('getdocs');
                }
              },
              error: function error(err, xH) {
                alert(err.responseText);
              }
            });
          } else {
            alert("Media name is required.");
          }
        }
      },
      deleteAsset: function deleteAsset(id) {
        var that = this;
        var mediaType = this.mediaType;
        var token = that.get('session.data.authenticated.access_token');
        var r = confirm("Are you sure you want to delete this asset?");
        if (r) {
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/assets/' + id,
            headers: {
              Authorization: 'Bearer ' + token
            },
            type: 'DELETE',
            success: function success(res) {
              that.set('uploadFile', false);
              that.set('mediaPreview', "");
              (0, _jquery.default)('#edit-asset' + id).slideUp(1000);
              if (mediaType == "IM") {
                that.set('images', []);
                that.send('getImages');
              }
              if (mediaType == "SV") {
                that.set('svgs', []);
                that.send('getSvgs');
              }
              if (mediaType == "AD") {
                that.set('audio', []);
                that.send('getAudio');
              }
              if (mediaType == "DC") {
                that.set('docs', []);
                that.send('getdocs');
              }
            },
            error: function error(err, xH) {
              alert(err.responseText);
            }
          });
        }
      }
    },
    authenticateVimeo: function authenticateVimeo() {
      var that = this;
      var client_secret = _environment.default.APP.VIMEO_CLIENT_SECRET;
      if (!this.vimeo_access_token) {
        var settings = {
          "url": "https://api.vimeo.com/oauth/authorize/client",
          "method": "POST",
          "timeout": 0,
          "headers": {
            "Authorization": "basic " + client_secret,
            "Accept": "application/vnd.vimeo.*+json;version=3.4",
            "Content-Type": "application/json"
          },
          "data": JSON.stringify({
            "grant_type": "client_credentials",
            "scope": "public, private"
          })
        };
        _jquery.default.ajax(settings).done(function (response) {
          var access_token = response.access_token;
          that.set('vimeo_access_token', access_token);
          that.getVideos(access_token);
        });
      } else {
        that.getVideos(this.vimeo_access_token);
      }
    },
    getVideos: function getVideos(access_token) {
      var that = this;
      var searchasset = this.get('searchasset');
      _jquery.default.ajax({
        url: "https://api.vimeo.com/users/140901463/videos?query=" + searchasset + '&per_page=40',
        // url: "https://api.vimeo.com/users/140901463/folders/4527475?query=" + searchasset + '&per_page=40',
        type: 'GET',
        headers: {
          Authorization: 'Bearer ' + access_token
        },
        success: function success(res) {
          console.log(res);
          if (that.isDestroyed) {
            return;
          }
          that.set('videos', res.data);
        },
        error: function error(err, xH) {}
      });
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function newAlert(message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }
  });
  _exports.default = _default;
});
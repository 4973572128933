define("momentumhub/helpers/custom-widgets/index", ["exports", "momentumhub/helpers/custom-widgets/rating", "momentumhub/helpers/custom-widgets/quiz", "momentumhub/helpers/custom-widgets/random-select", "survey-core", "survey-knockout"], function (_exports, _rating, _quiz, _randomSelect, _surveyCore, Survey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Add to survey;
  var AddCustomWidgets = function AddCustomWidgets(Survey) {
    Survey = Survey || window.Survey;
    console.log("Custom widgets function");
    if (Survey.CustomWidgetCollection.Instance.widgetsValues.length === 0) {
      _surveyCore.CustomWidgetCollection.Instance.add(_rating.default);
      _surveyCore.CustomWidgetCollection.Instance.add(_quiz.default);
      _surveyCore.CustomWidgetCollection.Instance.add(_randomSelect.default);
      console.log("Custom widgets being added");
      Survey.CustomWidgetCollection.Instance.addCustomWidget(_rating.default, "customtype");
      Survey.CustomWidgetCollection.Instance.addCustomWidget(_quiz.default, "customtype");
      Survey.CustomWidgetCollection.Instance.addCustomWidget(_randomSelect.default, "customtype");
    }
  };
  var _default = AddCustomWidgets;
  _exports.default = _default;
});
define("momentumhub/routes/power-thinking", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment", "survey-knockout", "momentumhub/helpers/custom-widgets", "survey-pdf", "blockly/msg/en"], function (_exports, _authenticatedRouteMixin, _environment, Survey, _customWidgets, SurveyPDF, _en) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    init: function init() {
      this._super.apply(this, arguments);
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
      } else {
        this.set('isTeenager', true);
      }
    },
    model: function model(param) {
      var modules = [];
      if (this.get('isTeenager')) {
        modules = [_environment.default.APP.POWER_THINKING_TEEN_MOD1, _environment.default.APP.POWER_THINKING_TEEN_MOD2, _environment.default.APP.POWER_THINKING_TEEN_MOD3, _environment.default.APP.POWER_THINKING_TEEN_MOD4];
      } else {
        modules = [_environment.default.APP.POWER_THINKING_CHILD_MOD1, _environment.default.APP.POWER_THINKING_CHILD_MOD2, _environment.default.APP.POWER_THINKING_CHILD_MOD3, _environment.default.APP.POWER_THINKING_CHILD_MOD4];
      }
      return Ember.RSVP.hash({
        modules: this.store.query('module', {
          identifiers: modules.join(",")
        }).then(function (results) {
          return results.toArray().sort(function (a, b) {
            return modules.indexOf(a.get('identifier')) - modules.indexOf(b.get('identifier'));
          });
        })
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});
define("momentumhub/controllers/surveys", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    surveys: [],
    surveyModel: [],
    selectedSurvetId: "",
    selectedSurveyTags: "",
    selectedSurveyName: "",
    delayTimer: "",
    deleteComponentName: "",
    deleteComponentTitle: "",
    deleteComponentId: "",
    survey_search: "",
    description: "",
    orderByOption: [{
      "id": 1,
      "name": "Search By Name or Id"
    }, {
      "id": 2,
      "name": "Search By Tags"
    }
    // { "id": 3, "name": "Search By Date Created" },
    // { "id": 4, "name": "Status" },
    ],

    isPreviousAvailable: "",
    isNextAvailable: "",
    pageNumber: 1,
    totalSurveys: "",
    totalViewableSurveys_start: "",
    totalViewableSurveys_end: "",
    actions: {
      createSurvey: function createSurvey() {
        $('.hover_bkgr_fricc-study-survey').show();
      },
      editTags: function editTags(surveyId, surveyTags, surveyName, description, survey) {
        this.set('selectedSurvetId', surveyId);
        this.set('selectedSurveyTags', surveyTags);
        this.set('selectedSurveyName', surveyName);
        this.set('description', description);
        this.set('allowPrintSurvey', survey.allow_print);
        $('.hover_bkgr_fricc-module-tags').show();
      },
      editSurveys: function editSurveys(surveyId, surveyText) {
        var localStorageName = "survey-" + surveyId;
        window.localStorage.setItem(localStorageName, surveyText);
        if (typeof surveyText === "string") {
          window.localStorage.setItem(localStorageName, surveyText);
        } else {
          window.localStorage.setItem(localStorageName, JSON.stringify(surveyText));
        }
        this.transitionToRoute('create-survey', surveyId);
      },
      previewSurvey: function previewSurvey(surveyId, surveyText) {
        this.transitionToRoute('survey-preview', surveyId);
      },
      previousModules: function previousModules() {
        this.pageNumber = this.pageNumber - 1;
        var module_search = this.get('module_search');
        if (module_search) {
          this.send('surveySearch');
        } else {
          this.send('getPaginatedSurveys');
        }
      },
      nextModules: function nextModules() {
        this.pageNumber = this.pageNumber + 1;
        var module_search = this.get('module_search');
        if (module_search) {
          this.send('surveySearch');
        } else {
          this.send('getPaginatedSurveys');
        }
      },
      surveySearch: function surveySearch() {
        var that = this;
        var survey_search = this.get('survey_search');
        var e = document.getElementById('order_by_survey');
        var option = e.options[e.selectedIndex].value;
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          if (survey_search) {
            if (option == 1 || option == "selin") {
              that.send('getPaginatedSurveys');
            }
            if (option == 2) {
              that.set('surveys', []);
              that.store.query('survey', {
                tags: survey_search,
                test: false
              }).then(function (surveys) {
                that.set('surveys', surveys);
                that.set('isNextAvailable', "");
                that.set('isPreviousAvailable', "");
              });
            }
          } else {
            that.set('pageNumber', 1);
            that.send('getPaginatedSurveys');
          }
        }, 1000);
      },
      getPaginatedSurveys: function getPaginatedSurveys() {
        var that = this;
        var page = this.pageNumber;
        var numberOfRecords = this.getRecordCount();
        var survey_search = this.get('survey_search');
        that.store.query('survey', {
          page: that.pageNumber,
          id_or_name: survey_search,
          page_size: numberOfRecords
        }).then(function (surveys) {
          that.set('surveys', surveys);
          that.set('isNextAvailable', surveys.meta.next);
          that.set('isPreviousAvailable', surveys.meta.previous);
          that.set('totalSurveys', surveys.meta.count);
          that.set('totalViewableSurveys_start', page * numberOfRecords - numberOfRecords + 1);
          if (surveys.meta.count < numberOfRecords) {
            that.set('totalViewableSurveys_end', surveys.meta.count);
          } else {
            that.set('totalViewableSurveys_end', page * numberOfRecords);
          }
        });
      },
      /* Delete Component */
      closeDeleteComponent: function closeDeleteComponent() {
        $('.hover_bkgr_fricc-delete-comp').hide();
        this.set('deleteComponentId', "");
        this.set('deleteComponentName', "");
        this.set('deleteComponentTitle', "");
      },
      openDeleteComponent: function openDeleteComponent(text, id, name) {
        this.set('deleteComponentName', text);
        this.set('deleteComponentTitle', name);
        this.set('deleteComponentId', id);
        $('.hover_bkgr_fricc-delete-comp').show();
      },
      deleteComponentFromApi: function deleteComponentFromApi() {
        var that = this;
        var token = that.get('session.data.authenticated.access_token');
        var deleteComponentId = this.deleteComponentId;
        $.ajax({
          url: _environment.default.APP.API_HOST + '/surveys/' + deleteComponentId,
          headers: {
            Authorization: 'Bearer ' + token
          },
          type: 'DELETE',
          success: function success(res) {
            that.send('getPaginatedSurveys');
            $('.hover_bkgr_fricc-delete-comp').hide();
            that.set('deleteComponentId', "");
            that.set('deleteComponentName', "");
            that.set('deleteComponentTitle', "");
          },
          error: function error(err, xH) {
            alert(err.responseText);
          }
        });
      },
      orderBy: function orderBy() {
        var e = document.getElementById('order_by_survey');
        var option = e.options[e.selectedIndex].value;
        if (option == 1) {
          $('#survey_search_input').attr('placeholder', 'Search Modules by Name or Id');
        } else if (option == 2) {
          $('#survey_search_input').attr('placeholder', 'Eg: Happiness, Anxiety');
        } else if (option == 3) {
          $('#survey_search_input').attr('placeholder', 'Eg: MM/DD/YYYY');
        } else {
          $('#survey_search_input').attr('placeholder', 'In Progress or Complete');
        }
        this.send('surveySearch');
      },
      refreshRouter: function refreshRouter() {
        this.send('getPaginatedSurveys');
      }
    },
    getRecordCount: function getRecordCount() {
      var count = 15;
      var mq1 = window.matchMedia("(min-width: 500px)");
      if (mq1.matches) {
        count = 16;
      }
      var mq2 = window.matchMedia("(min-width: 1350px)");
      if (mq2.matches) {
        count = 15;
      }
      var mq3 = window.matchMedia("(min-width: 1680px)");
      if (mq3.matches) {
        count = 18;
      }
      return count;
    }
  });
  _exports.default = _default;
});
define("momentumhub/routes/modules", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment", "momentumhub/mixins/guardian-session-mixin"], function (_exports, _authenticatedRouteMixin, _environment, _guardianSessionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _guardianSessionMixin.default, {
    session: Ember.inject.service(),
    currentStudy: "",
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model() {
      var isParticipant = this.session.get("data.isParticipant") || this.session.get('data.user_type') === 'CL';
      var currentUser = this.session.get("data.userId");

      //********* Only for Parent selection input */
      var viewingId = this.session.get("data.viewingId");
      if (viewingId) {
        currentUser = viewingId;
      }
      //**************************************** */
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers?is_onboarding=false&user=' + currentUser,
        type: 'GET',
        async: false,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function success(res) {
          var studyuser = res.studyuser[0];
          if (studyuser) {
            that.set("currentStudy", studyuser.study);
          }
        },
        error: function error(err, xH) {
          return res.err;
        }
      });
      if (isParticipant) {
        var user_email = this.session.get("data.user_email");
        var currentStudy = this.get('currentStudy');
        return Ember.RSVP.hash({
          modules: this.store.query("participantmodule", {
            participant: currentUser,
            study: currentStudy,
            study__on_boarding_study: false,
            user: currentUser
          }),
          // normal completed module
          completedModules: this.store.query("participantmodule", {
            module_type: 'NM',
            participant: currentUser,
            completed: true,
            study: currentStudy,
            is_resource: false,
            study__on_boarding_study: false,
            user: currentUser
          }),
          // resources modules
          resourcesModules: this.store.query("participantmodule", {
            participant: currentUser,
            is_resource: true,
            study: currentStudy,
            available: true,
            study__on_boarding_study: false,
            user: currentUser
          }),
          // optional modules
          optionalModules: this.store.query("participantmodule", {
            module_type: 'OM',
            participant: currentUser,
            study: currentStudy,
            available: true,
            is_resource: false,
            study__on_boarding_study: false,
            user: currentUser
          }),
          // required modules
          requiredModules: this.store.query("participantmodule", {
            module_type: 'RM',
            participant: currentUser,
            study: currentStudy,
            is_resource: false,
            available: true,
            study__on_boarding_study: false,
            user: currentUser
          })
        });
      } else {
        var count = this.getRecordCount();
        return Ember.RSVP.hash({
          modules: this.store.query("module", {
            page: 1,
            page_size: count
          })
        });
      }
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.settingAccessTokenHedgedoc();
      var modulesModel = model.modules;
      this.controllerFor("modules").set('modules', modulesModel);
      this.controllerFor("modules").set('moduleModel', modulesModel);
      this.controllerFor("modules").set('isPreviousAvailable', modulesModel.meta.previous);
      this.controllerFor("modules").set('isNextAvailable', modulesModel.meta.next);
      this.controllerFor("modules").set('totalModules', modulesModel.meta.count);
      this.controllerFor("modules").set('totalViewableModules_start', 1);
      var count = this.getRecordCount();
      this.controllerFor("modules").set('totalViewableModules_end', count);
      this.controllerFor("modules").set('module_search', "");
      var isParticipant = this.session.get('data.isParticipant');
      // Only show modules with filter page = false for Participants
      if (isParticipant) {
        this.controllerFor("modules").set('pagefilter', false);
      } else {
        this.controllerFor("modules").set('pagefilter', "");
      }
      this.controllerFor("modules").set('module_search', "");
      this.controllerFor("modules").set('showModule', true);
      this.controllerFor("modules").set('pageNumber', 1);
      this.controllerFor("modules").send('getCurrentStudy');
      this.controllerFor('modules').setHelpButtonModule();
      this.controllerFor('modules').send('fetchStarsArray');
    },
    settingAccessTokenHedgedoc: function settingAccessTokenHedgedoc() {
      var existing_token = localStorage.getItem("access_token_hedgedoc");
      var accessToken = this.get('session.data.authenticated.access_token');
      if (existing_token != accessToken) {
        localStorage.setItem("access_token_hedgedoc", accessToken);
      }
    },
    getRecordCount: function getRecordCount() {
      var count = 15;
      var mq1 = window.matchMedia("(min-width: 500px)");
      if (mq1.matches) {
        count = 16;
      }
      var mq2 = window.matchMedia("(min-width: 1350px)");
      if (mq2.matches) {
        count = 15;
      }
      var mq3 = window.matchMedia("(min-width: 1680px)");
      if (mq3.matches) {
        count = 18;
      }
      return count;
    }
  });
  _exports.default = _default;
});
define("momentumhub/components/momentum-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- Spinner Component --}}
  <div class="momentum-spinner-component">
      <div class="hover_bkgr_fricccc_spinner1">
         <div class="inner">
             <img class="landing-page-logo bounce" src="/assets/participantDashboard/logo1.svg" alt="Momentum Hub">
             <p>Preparing components.....</p>
  
  
         </div>
      </div>
  </div>
  
  
  
  {{yield}}
  
  */
  {
    "id": "NZLKg10f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"momentum-spinner-component\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hover_bkgr_fricccc_spinner1\"],[12],[2,\"\\n       \"],[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n           \"],[10,\"img\"],[14,0,\"landing-page-logo bounce\"],[14,\"src\",\"/assets/participantDashboard/logo1.svg\"],[14,\"alt\",\"Momentum Hub\"],[12],[13],[2,\"\\n           \"],[10,\"p\"],[12],[2,\"Preparing components.....\"],[13],[2,\"\\n\\n\\n       \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "momentumhub/components/momentum-spinner.hbs"
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});
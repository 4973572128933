define("momentumhub/controllers/usermanagement/links", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    studies: [],
    sites: [],
    participantGroups: [],
    selectedStudy: [],
    selectedSite: [],
    selectedGroup: [],
    createdLink: "",
    errorMessage: "",
    actions: {
      resetSettings: function resetSettings() {
        this.set('selectedStudy', []);
        this.set('selectedSite', []);
        this.set('selectedGroup', []);
        this.set('createdLink', "");
        this.set('errorMessage', "");
        this.set('studies', this.model.studies);
        this.set('sites', []);
        this.set('participantGroups', []);
        this.set('searchStudy', "");
        this.set('searchSite', "");
        this.set('searchGroup', "");
        $('.studyclass').removeClass('active');
        $('.siteclass').removeClass('active');
        $('.groupclass').removeClass('active');
      },
      //Filters
      studyFilter: function studyFilter() {
        var searchStudy = this.get('searchStudy');
        this.set('selectedStudy', []);
        if (searchStudy) {
          var a = this.studies.filter(function (e) {
            return e.study_name.toLowerCase().includes(searchStudy.toLowerCase());
          });
          this.set('studies', a);
        } else {
          this.set('studies', this.model.studies);
        }
      },
      siteFilter: function siteFilter() {
        var searchSite = this.get('searchSite');
        this.set('selectedSite', []);
        if (searchSite) {
          var a = this.model.sites.filter(function (e) {
            return e.name.toLowerCase().includes(searchSite.toLowerCase());
          });
          this.set('sites', a);
        } else {
          this.set('sites', this.model.sites);
        }
      },
      groupFilter: function groupFilter() {
        var searchGroup = this.get('searchGroup');
        this.set('selectedGroup', []);
        if (searchGroup) {
          var a = this.participantGroups.filter(function (e) {
            return e.name.toLowerCase().includes(searchGroup.toLowerCase());
          });
          this.set('participantGroups', a);
        } else {
          this.set('participantGroups', this.model.participantGroups);
        }
      },
      //Select
      selectStudy: function selectStudy(study) {
        var accessToken = this.get('session.data.authenticated.access_token');
        this.set('selectedStudy', study);
        this.set('sites', []);
        this.set('participantGroups', []);
        var that = this;
        $('.studyclass').removeClass('active');
        $('#study' + study.id).addClass('active');
        $.ajax({
          url: _environment.default.APP.API_HOST + '/studies/' + study.id,
          type: 'GET',
          headers: {
            "Authorization": "Bearer " + accessToken
          },
          success: function success(res) {
            var siteId = res.study.site;
            var groupsId = res.study.participant_groups;
            if (siteId) {
              that.store.findRecord('mommentumhubsite', siteId).then(function (siteId) {
                that.sites.pushObject(siteId);
              });
            }
            if (groupsId) {
              var groups = groupsId.toString();
              that.store.query('participantgroup', {
                'id__in': groups
              }).then(function (groups) {
                groups.map(function (group) {
                  that.participantGroups.pushObject(group);
                });
              });
            }
          },
          error: function error(err, xH) {
            var str = err.responseText;
            str = str.split(':["').pop();
            str = str.split('"]')[0];
          }
        });
      },
      selectSite: function selectSite(site) {
        this.set('selectedSite', site);
        $('.siteclass').removeClass('active');
        $('#site' + site.id).addClass('active');
      },
      selectGroup: function selectGroup(group) {
        this.set('selectedGroup', group);
        $('.groupclass').removeClass('active');
        $('#group' + group.id).addClass('active');
      },
      // Retrieve Link

      retrievelink: function retrievelink() {
        var siteId = this.selectedSite.id;
        var studyId = this.selectedStudy.id;
        var groupId = this.selectedGroup.id;
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        if (studyId) {
          if (siteId) {
            if (groupId) {
              $.ajax({
                url: _environment.default.APP.API_HOST_INITIAL + '/generate-magic-link/' + studyId + '/' + siteId + '/' + groupId,
                type: 'GET',
                headers: {
                  "Authorization": "Bearer " + accessToken
                },
                success: function success(res) {
                  var generateMagicLink = res.generateMagicLink;
                  that.set('errorMessage', "");
                  that.set('createdLink', generateMagicLink.url);
                  that.patchLinkToTheStudy(generateMagicLink.url);
                  if (that.selectedStudy.draft) {
                    that.toast.warning('E04 - Participant will not be able to enroll to this study as it is in draft mode', 'Study in draft mode', {
                      "closeButton": false,
                      "debug": false,
                      "newestOnTop": false,
                      "progressBar": false,
                      "positionClass": "toast-top-right",
                      "preventDuplicates": false,
                      "onclick": null,
                      "showDuration": "300",
                      "hideDuration": "1000",
                      "timeOut": "30000",
                      "extendedTimeOut": "1000",
                      "showEasing": "swing",
                      "hideEasing": "linear",
                      "showMethod": "fadeIn",
                      "hideMethod": "fadeOut"
                    });
                  }
                },
                error: function error(err, xH) {
                  var str = err.responseText;
                  str = str.split('"error":"').pop();
                  str = str.split('"}}')[0];
                  that.set('errorMessage', str);
                  that.set('createdLink', "");
                }
              });
            } else {
              this.showError('groupouterBox');
            }
          } else {
            this.showError('siteouterBox');
          }
        } else {
          this.showError('studyouterBox');
        }
      },
      //Copy to the clipb board
      copyToTheClipboard: function copyToTheClipboard() {
        var createdLink = this.createdLink;
        var $temp = $("<input>");
        $("body").append($temp);
        var t = createdLink;
        t = t.trim();
        $temp.val(t).select();
        document.execCommand("copy");
        $temp.remove();
        $('.copied').show();
        setTimeout(function () {
          $('.copied').hide();
        }, 3000);
      }
    },
    patchLinkToTheStudy: function patchLinkToTheStudy(default_magic_link) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var studyId = this.selectedStudy.id;
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studies/' + studyId,
        type: 'PATCH',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "study": {
            "default_magic_link": default_magic_link
          }
        }),
        success: function success(res) {},
        error: function error(err, xH) {
          var errorMessage = err.responseText;
          alert(errorMessage);
        }
      });
    },
    showError: function showError(id) {
      $("#" + id).addClass("error");
      setTimeout(function () {
        $("#" + id).removeClass("error");
      }, 2000);
    }
  });
  _exports.default = _default;
});
define("momentumhub/utils", ["exports", "survey-pdf"], function (_exports, SurveyPDF) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCurrentUser = getCurrentUser;
  _exports.getDayAndTimeOfCurrentWeek = getDayAndTimeOfCurrentWeek;
  _exports.getParticipantCategory = getParticipantCategory;
  _exports.isTeenager = isTeenager;
  _exports.setupSurveyPrint = setupSurveyPrint;
  function getDayAndTimeOfCurrentWeek(dayOfWeek, time) {
    if (!time) {
      return;
    }
    var currentTime = new Date();
    var time = time.split(':');
    var currentDayOfWeek = currentTime.getDay();
    var difference = parseInt(dayOfWeek) - currentDayOfWeek;
    currentTime.setDate(currentTime.getDate() + difference);
    currentTime.setHours(time[0]);
    currentTime.setMinutes(time[1]);
    return currentTime.toISOString();
  }
  function getParticipantCategory() {
    var participantAge = localStorage.getItem('participantAge');
    if (participantAge <= 12) {
      return 'child';
    } else {
      return 'teen';
    }
  }
  function isTeenager() {
    return getParticipantCategory() === 'teen';
  }
  function savePdf(survey, surveyData, json) {
    var options = {
      fontSize: 14,
      margins: {
        left: 10,
        right: 10,
        top: 10,
        bot: 10
      },
      format: [survey.pdfWidth || 210, survey.pdfHeight || 297],
      haveCommercialLicense: true
    };
    var surveyPdf = new SurveyPDF.SurveyPDF(json, options);
    surveyPdf.data = surveyData;
    surveyPdf.save();
  }
  function setupSurveyPrint(survey, json) {
    survey.addNavigationItem({
      id: 'pdf-export',
      title: 'Save as PDF',
      action: function action() {
        return savePdf(survey, survey.data, json);
      }
    });
  }
  function getCurrentUser(session) {
    var userId = session.get('data.viewingId') || session.get('data.userId');
    var userEmail = session.get('data.viewingEmail') || session.get('data.user_email');
    return {
      'email': userEmail,
      'id': userId
    };
  }
});
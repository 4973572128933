define("momentumhub/controllers/studies", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    currentUser: [],
    studies: [],
    isNextAvailable: "",
    isPreviousAvailable: "",
    study_search: "",
    pageNumber: 1,
    delayTimer: "",
    showArchived: false,
    buttonColor: '#8b8b8b',
    actions: {
      studySearch: function studySearch() {
        var that = this;
        this.set('pageNumber', 1);
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.send('getPaginatedStudies');
        }, 1000);
      },
      showArchivedStudy: function showArchivedStudy() {
        this.set('showArchived', !this.showArchived);
        if (this.showArchived) {
          this.set('buttonColor', '#344D90');
        } else {
          this.set('buttonColor', '#8b8b8b');
        }
        this.send('getPaginatedStudies');
      },
      previousStudies: function previousStudies() {
        this.pageNumber = this.pageNumber - 1;
        this.send('getPaginatedStudies');
      },
      nextStudies: function nextStudies() {
        this.pageNumber = this.pageNumber + 1;
        this.send('getPaginatedStudies');
      },
      createStudy: function createStudy() {
        $('.hover_bkgr_fricc-study-comp').show();
      },
      routeToStudyAnalytics: function routeToStudyAnalytics(id) {
        this.transitionToRoute('study-analytics', id);
      },
      editStudy: function editStudy(id) {
        this.transitionToRoute('study-builder', id);
      },
      testfunc: function testfunc() {
        window.parent.zipPhoneCallback("zipphone");
      },
      getPaginatedStudies: function getPaginatedStudies() {
        var that = this;
        var search = this.get('study_search');
        var showArchived = this.get('showArchived');
        that.store.query('study', {
          page: that.pageNumber,
          archived: showArchived,
          search: search
        }).then(function (studies) {
          that.set('studies', studies.toArray());
          that.set('isNextAvailable', studies.meta.next);
          that.set('isPreviousAvailable', studies.meta.previous);
        });
      }
    }
  });
  _exports.default = _default;
});
define("momentumhub/adapters/embedded", ["exports", "ember-data", "momentumhub/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service('session'),
    authorizer: 'authorizer:application',
    host: _environment.default.APP.API_HOST,
    headers: Ember.computed('session.authToken', function () {
      var auth = _environment.default.APP.super_user;
      if (this.get('session.isAuthenticated')) {
        auth = "Bearer " + this.get("session.data.authenticated.access_token");
      }
      return {
        "Authorization": auth
      };
    }),
    updateRecord: function updateRecord(store, type, snapshot) {
      var changedAttributes = snapshot.changedAttributes();
      var formData = new FormData();
      Object.keys(changedAttributes).forEach(function (attributeName) {
        var newValue = changedAttributes[attributeName][1];
        formData.append(attributeName, newValue);
      });
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return $.ajax({
        url: url,
        headers: this.get('headers'),
        data: formData,
        contentType: false,
        processData: false,
        type: 'PATCH'
      });
    }
  });
  _exports.default = _default;
});
define("momentumhub/components/participant-dashboard", ["exports", "jquery", "momentumhub/config/environment", "animejs/lib/anime.es.js", "momentumhub/utils"], function (_exports, _jquery, _environment, _animeEs, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    users: [],
    isTeenager: false,
    lastStudyId: "",
    studyStatus: "",
    isStudyAvailable: false,
    moods: [],
    isTodayCheckinAvailable: false,
    todayCheckinId: "",
    progressPercentageError: "",
    progresspercentage: 0,
    showDashboard: false,
    showFeelingsTracker: false,
    showClueCalmers: false,
    showPowerThinking: false,
    showClimbingPlans: false,
    showMoodBoost: false,
    rewardVars: null,
    currentUser: null,
    init: function init() {
      this._super.apply(this, arguments);
      var currentUser = (0, _utils.getCurrentUser)(this.session);
      this.set('currentUser', currentUser);
      var participantAge = window.localStorage.getItem("participantAge");
      if (participantAge <= 12) {
        this.set('isTeenager', false);
      } else {
        this.set('isTeenager', true);
      }
      this.getUserStudy();
      this.getParticipantMoods();
      this.getUserRewards();
      this.updateTheme();
    },
    actions: {
      showMenu: function showMenu() {
        (0, _jquery.default)('.participant-left-navbar').show();
      },
      /************************* Get last study user id ************* */
      routeToStudyView: function routeToStudyView() {
        /* const lastStudyId = this.lastStudyId;
        const url = window.location.origin + '/view/' + lastStudyId + '/na';
        window.location.href = url; */

        // this.get('router').transitionTo('studyview', lastStudyId, "na");
        this.get('router').transitionTo('modules');
      },
      /************************* Post today's mood ************* */
      postMood: function postMood(type, emotion) {
        var that = this;
        if (that.get('session.data.user_type') != 'PT') {
          return;
        }
        var token = that.get('session.data.authenticated.access_token');
        var userId = this.get('currentUser.id');
        (0, _jquery.default)('.mood').removeClass('selected-mood');
        (0, _jquery.default)('.mood.face-' + emotion).addClass('selected-mood');

        // Current Date
        var todayDate = new Date();
        var year = todayDate.getFullYear();
        var month = todayDate.getMonth() + 1;
        var date = todayDate.getDate();
        // const newCurrentDate = year + "-" + month + "-" + date;

        var data_to_api = {
          "mood_type": type,
          "user": userId
        };
        _jquery.default.ajax({
          url: _environment.default.APP.API_HOST + '/moods',
          headers: {
            Authorization: 'Bearer ' + token
          },
          type: 'POST',
          data: data_to_api,
          success: function success(res) {
            that.getParticipantMoods();
          },
          error: function error(err, xH) {
            alert(err.responseText);
          }
        });
      }
    },
    //******************** Get new or incomplete study ******************* */
    getUserStudy: function getUserStudy() {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var userId = this.get('currentUser.id');
      this.set('studyStatus', "");

      //1. If is onboarding study
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers?user=' + userId + '&completed=false&is_onboarding=true',
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function success(res) {
          var studyusers = res.studyuser;
          var length = studyusers.length;
          console.log(studyusers);
          var progresspercentage = 0;
          var stageCount = that.get('rewardVars.TR_session_count') || 0;
          if (length > 0) {
            that.set('isStudyAvailable', true);
            var lastStudy = length - 1;
            studyusers.forEach(function (studyuser, index) {
              if (index == lastStudy) {
                var lastStudyId = studyuser.id;
                var current_phase = studyuser.current_phase;
                var current_progress = studyuser.current_progress;
                progresspercentage = current_progress;
                var current_progress_error = studyuser.current_progress_error;
                that.set('lastStudyId', lastStudyId);
                if (current_phase) {
                  that.set('studyStatus', "Continue");
                } else {
                  that.set('studyStatus', "Start Study");
                }
                that.set('progressPercentageError', current_progress_error);
                that.set('progresspercentage', current_progress);
              }
            });
          } else {
            that.getStudyU();
          }
          that.set('progresspercentage', progresspercentage);
          var upto = Math.floor(progresspercentage / 10);
          if (upto < stageCount) {
            upto = stageCount;
          }
          if (upto === 0) {
            (0, _jquery.default)(".tree-animations .seed-1").addClass('show');
            (0, _jquery.default)(".tree-animations .seed-2").addClass('show');
          } else {
            (0, _jquery.default)(".tree-animations .seed-1").removeClass('show');
            (0, _jquery.default)(".tree-animations .seed-2").removeClass('show');
          }
          for (var i = 0; i < upto; i++) {
            (0, _jquery.default)(".tree-animations .stage-".concat(i + 1)).addClass('show');
          }
        },
        error: function error(err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          console.log(str);
        }
      });
    },
    //******************** Get new or incomplete study ******************* */
    getUserRewards: function getUserRewards() {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var currentUser = this.get('currentUser');
      var userId = currentUser.id;
      var user_email = currentUser.email;

      //1. If is onboarding study
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/userrewardvars?user=' + userId,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function success(res) {
          console.log('res', res.userrewardvar[0].reward_vars);
          var rewards = res.userrewardvar[0].reward_vars;
          that.set('rewardVars', rewards);
          if (rewards && rewards.TR_FeelingsTracker_available == 1) {
            that.set('showFeelingsTracker', true);
          }
          if (rewards && rewards.TR_ClueCalmers_available == 1) {
            that.set('showClueCalmers', true);
          }
          if (rewards && rewards.TR_PowerThinking_available == 1) {
            that.set('showPowerThinking', true);
          }
          if (rewards && rewards.TR_ClimbingPlans_available == 1) {
            console.log('inside climbing plans');
            that.set('showClimbingPlans', true);
          }
          if (rewards && rewards.TR_MoodBoost_available == 1) {
            that.set('showMoodBoost', true);
          }
          if (rewards && rewards.TR_ChangeFocus_available == 1) {
            that.set('showChangeFocus', true);
          }
          // const length = studyusers.length;
          // if (length > 0) {
          //     that.set('isStudyAvailable', true)
          //     const lastStudy = length - 1;
          //     studyusers.forEach(function (studyuser, index) {
          //         if (index == lastStudy) {
          //             const lastStudyId = studyuser.id;
          //             const current_phase = studyuser.current_phase;
          //             const current_progress = studyuser.current_progress;
          //             const current_progress_error = studyuser.current_progress_error;
          //             that.set('lastStudyId', lastStudyId)
          //             if (current_phase) { that.set('studyStatus', "Continue") }
          //             else { that.set('studyStatus', "Start Study") }
          //             // that.animate(current_progress);
          //             that.set('progressPercentageError', current_progress_error);
          //             that.set('progresspercentage', current_progress);
          //         }
          //     })
          // }
          // else {
          //     that.getStudyU();
          // }

          // // Temp to set progresspercentage, remove after studyusers is implemented
          // const progresspercentage = Number(localStorage.getItem('progresspercentage'))
          // that.set('progresspercentage', progresspercentage);
          // const upto = Math.floor(progresspercentage / 20);
          // for (let i = 0; i < upto; i++) {
          //     $(`.tree-animations .animate-${i + 1}`).addClass('show');
          // }
        },

        error: function error(err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          console.log(str);
        }
      });
    },
    getStudyU: function getStudyU() {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var userId = this.get('currentUser.id');
      this.set('studyStatus', "");
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers?user=' + userId + '&is_onboarding=false',
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function success(res) {
          var studyusers = res.studyuser;
          var length = studyusers.length;
          var progresspercentage = 0;
          console.log('studyuser', studyusers);
          if (length > 0) {
            that.set('isStudyAvailable', true);
            var lastStudy = length - 1;
            var studyIndex = 0;
            var studyuser = studyusers[studyIndex];
            var lastStudyId = studyuser.id;
            var current_phase = studyuser.current_phase;
            progresspercentage = studyuser.current_progress;
            var current_progress_error = studyuser.current_progress_error;
            that.set('lastStudyId', lastStudyId);
            if (!studyuser.completed || studyuser.required_module_id) {
              if (current_phase) {
                that.set('studyStatus', "Continue");
              } else {
                that.set('studyStatus', "Start Study");
              }
            } else {
              progresspercentage = 100;
            }
            that.set('progressPercentageError', current_progress_error);
            that.set('progresspercentage', progresspercentage);
          } else {
            that.set('isStudyAvailable', false);
          }
          that.set('progresspercentage', progresspercentage);
          var upto = Math.floor(progresspercentage / 10);
          if (upto === 0) {
            (0, _jquery.default)(".tree-animations .seed-1").addClass('show');
            (0, _jquery.default)(".tree-animations .seed-2").addClass('show');
          } else {
            (0, _jquery.default)(".tree-animations .seed-1").removeClass('show');
            (0, _jquery.default)(".tree-animations .seed-2").removeClass('show');
          }
          for (var i = 0; i < upto; i++) {
            (0, _jquery.default)(".tree-animations .stage-".concat(i + 1)).addClass('show');
          }
        },
        error: function error(err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          console.log(str);
        }
      });
    },
    //******************** Progress Animation ******************* */
    animate: function animate(percentage) {
      var divwidth = (0, _jquery.default)('.pabble-progress').width() - 5 * (0, _jquery.default)('.pabble-progress').width() / 100;
      var progress = percentage * divwidth / 100;
      (0, _animeEs.default)({
        targets: '.css-prop-demo .el',
        translateX: progress,
        // easing: 'easeInOutQuad',
        duration: 4000,
        easing: 'easeOutElastic(1, .8)'
      });
    },
    //******************** Participant mood ******************* */
    getParticipantMoods: function getParticipantMoods() {
      var that = this;
      var userId = this.get('currentUser.id');
      this.get('store').query('mood', {
        user: userId,
        page: 1
      }).then(function (moods) {
        that.set('moods', moods);
      });
    },
    updateTheme: function updateTheme() {
      console.log("Hello from changeTheme participant new  js");
      var participantAge = localStorage.getItem("participantAge");
      setTimeout(function () {
        var selectedTheme = localStorage.getItem("selected_theme");
        var isDarkTheme = localStorage.getItem("dark_theme");
        var isHighContrastTheme = localStorage.getItem("high-contrast");
        if (isDarkTheme === 'true') {
          (0, _jquery.default)('.participant-application-view').addClass('dark');
          (0, _jquery.default)('.participant-application-view').removeClass('contrast');
        }
        if (isHighContrastTheme === 'true') {
          (0, _jquery.default)('.participant-application-view').addClass('contrast');
          (0, _jquery.default)('.participant-application-view').removeClass('dark');
        }
        if (participantAge > 12) {
          (0, _jquery.default)('.participant-application-view').addClass('teenager');
        } else {
          (0, _jquery.default)('.participant-application-view').removeClass('teenager');
        }
      }, 100);
      var viewingAge = this.session.get('data.viewingAge');
      var viewingDark_theme = this.session.get('data.viewingDark_theme');
      if (viewingAge) {
        participantAge = viewingAge;
      }
      if (participantAge) {
        var selected_theme = localStorage.getItem("selected_theme");
        if (viewingDark_theme != undefined) {
          if (viewingDark_theme) {
            selected_theme = "true";
          } else {
            selected_theme = "false";
          }
        }
        if (selected_theme == "false") {
          if (participantAge <= 12) {
            //primary colors
            document.documentElement.style.setProperty('--primary-color-1', "#4281A4");
            document.documentElement.style.setProperty('--primary-color-2', "#48A9A6");
            document.documentElement.style.setProperty('--primary-color-3', "#D4B483");
            document.documentElement.style.setProperty('--primary-color-4', "#C1666B");
            //secondary colors
            document.documentElement.style.setProperty('--secondary-color-1', "#1A3A61");
            document.documentElement.style.setProperty('--secondary-color-2', "#E4DFDA");
            document.documentElement.style.setProperty('--secondary-color-3', "#80606E");
            //font colors
            document.documentElement.style.setProperty('--font-color-1', "#1A3A61");
            document.documentElement.style.setProperty('--font-color-2', "#737373");
            document.documentElement.style.setProperty('--font-color-3', "#131929");
            document.documentElement.style.setProperty('--font-color-4', "#FFFFFF");

            //Menu color
            document.documentElement.style.setProperty('--font-inverted-white', "white");
            document.documentElement.style.setProperty('--font-color-5', "#1A3A61");
            document.documentElement.style.setProperty('--menu-color-1', "#003E8F");
            document.documentElement.style.setProperty('--menu-color-2', "#28B4CC");
            document.documentElement.style.setProperty('--menu-color-3', "#EF5406");
            document.documentElement.style.setProperty('--menu-color-4', "#19C89C");
            document.documentElement.style.setProperty('--menu-color-5', "#822EB2");
            document.documentElement.style.setProperty('--menu-color-6', "#FACE03");

            // single-page module font colors
            document.documentElement.style.setProperty('--module-h1-color', "#EF5406");
            document.documentElement.style.setProperty('--module-h2-color', "#822EB2");
            document.documentElement.style.setProperty('--module-h3-color', "#003E8F");
            document.documentElement.style.setProperty('--module-h4-color', "#28B4CC");
            document.documentElement.style.setProperty('--module-h5-color', "#000000");
            document.documentElement.style.setProperty('--module-h6-color', "#FFB744");
          } else if (participantAge > 12) {
            setTimeout(function () {
              (0, _jquery.default)('.participant-application-view').addClass('teenager');
            }, 100);

            //primary colors
            document.documentElement.style.setProperty('--primary-color-1', "#28B4CC");
            document.documentElement.style.setProperty('--primary-color-2', "#19C89C");
            document.documentElement.style.setProperty('--primary-color-3', "#FACE03");
            document.documentElement.style.setProperty('--primary-color-4', "#822EB2");
            //secondary colors
            document.documentElement.style.setProperty('--secondary-color-1', "#EF5406");
            document.documentElement.style.setProperty('--secondary-color-2', "#003E8F");
            document.documentElement.style.setProperty('--secondary-color-3', "#FACE03");
            //font colors
            document.documentElement.style.setProperty('--font-color-1', "#1A3A61");
            document.documentElement.style.setProperty('--font-color-2', "#737373");
            document.documentElement.style.setProperty('--font-color-3', "#131929");
            document.documentElement.style.setProperty('--font-color-4', "#FFFFFF");

            //Menu color
            document.documentElement.style.setProperty('--font-inverted-white', "white");
            document.documentElement.style.setProperty('--font-color-5', "#1A3A61");
            document.documentElement.style.setProperty('--menu-color-1', "#4281A4");
            document.documentElement.style.setProperty('--menu-color-2', "#7C606B");
            document.documentElement.style.setProperty('--menu-color-3', "#C1666B");
            document.documentElement.style.setProperty('--menu-color-4', "#48A9A6");
            document.documentElement.style.setProperty('--menu-color-5', "#1A3A61");
            document.documentElement.style.setProperty('--menu-color-6', "#D4B483");

            // single-page module font colors
            document.documentElement.style.setProperty('--module-h1-color', "#48A9A6");
            document.documentElement.style.setProperty('--module-h2-color', "#C1666B");
            document.documentElement.style.setProperty('--module-h3-color', "#000000");
            document.documentElement.style.setProperty('--module-h4-color', "#7C606B");
            document.documentElement.style.setProperty('--module-h5-color', "#000000");
            document.documentElement.style.setProperty('--module-h6-color', "#1A3A61");
          }
        } else {
          // document.documentElement.style.setProperty('--primary-color-1', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--primary-color-2', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--primary-color-3', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--primary-color-4', "rgba(0, 0, 0, 0.801)");
          // //secondary colors
          // document.documentElement.style.setProperty('--secondary-color-1', "white");
          // document.documentElement.style.setProperty('--secondary-color-2', "white");
          // document.documentElement.style.setProperty('--secondary-color-3', "white");
          // //font colors
          // document.documentElement.style.setProperty('--font-color-1', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--font-color-2', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--font-color-3', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--font-color-4', "rgba(0, 0, 0, 0.801)");

          // //Menu color
          // document.documentElement.style.setProperty('--font-inverted-white', "white");
          // document.documentElement.style.setProperty('--font-color-5', "white");

          // document.documentElement.style.setProperty('--menu-color-1', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--menu-color-2', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--menu-color-3', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--menu-color-4', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--menu-color-5', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--menu-color-6', "rgba(0, 0, 0, 0.801)");
        }
      }
    }
  });
  _exports.default = _default;
});
define("momentumhub/helpers/custom-widgets/quiz", ["exports", "survey-knockout", "survey-core"], function (_exports, Survey, SurveyCore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var Quiz = {
    name: "quiz",
    title: "Quiz",
    iconName: "",
    widgetIsLoaded: function widgetIsLoaded() {
      return true;
    },
    isFit: function isFit(question) {
      return question.getType() === 'quiz';
    },
    activatedByChanged: function activatedByChanged(activatedBy) {
      Survey.JsonObject.metaData.addClass("quiz", [], null, "text");
      Survey.JsonObject.metaData.addProperty("quiz", {
        name: "choices:itemvalues",
        category: "choices"
      });
      Survey.JsonObject.metaData.addProperty('quiz', {
        name: 'showRightOrWrongBox:boolean',
        category: 'Right or Wrong Box'
      });
      Survey.JsonObject.metaData.addProperty("quiz", {
        name: "correctAnswer:string",
        category: "Correct Answer"
      });
      Survey.JsonObject.metaData.addProperty("page", {
        name: "choices:itemvalues"
      });
      Survey.JsonObject.metaData.addProperty("page", {
        name: "correctAnswer:string"
      });
      SurveyCore.JsonObject.metaData.addClass("quiz", [], null, "text");
      SurveyCore.JsonObject.metaData.addProperty("quiz", {
        name: "choices:itemvalues",
        category: "choices"
      });
      SurveyCore.JsonObject.metaData.addProperty('quiz', {
        name: 'showRightOrWrongBox:boolean',
        category: 'Right or Wrong Box'
      });
      SurveyCore.JsonObject.metaData.addProperty("quiz", {
        name: "correctAnswer:string",
        category: "Correct Answer"
      });
      SurveyCore.JsonObject.metaData.addProperty("page", {
        name: "choices:itemvalues"
      });
      SurveyCore.JsonObject.metaData.addProperty("page", {
        name: "correctAnswer:string"
      });
    },
    isDefaultRender: false,
    htmlTemplate: "<div class=\"quiz-question\"><div class=\"quiz-choices\"></div><div class=\"correct-answer answer-response\">&#10004; Correct Answer</div><div class=\"wrong-answer answer-response\">\n&#10060; Wrong Answer</div></div>",
    afterRender: function afterRender(question, el) {
      var createCustomSurvey = function createCustomSurvey() {
        var wrapperElement = el.closest('.sv-row');
        if (wrapperElement) wrapperElement.classList.add('quiz-wrapper');
        var choices = question.choices;
        var showRightOrWrongBox = question.showRightOrWrongBox;
        var querySelector = el.querySelector('.quiz-choices');
        var choicesHtml = '';
        choices.map(function (choice) {
          var data = choice.getData();
          var isImage = data.text && data.text.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gmi) != null;
          var divContent = data.text;
          var customClass = '';
          if (isImage) {
            divContent = '<img src="' + data.text + '" data-value="' + data.value + '">';
            customClass = 'image-choice';
          }
          choicesHtml += '<div class="quiz-choice ' + customClass + '" data-value="' + data.value + '">' + divContent + '</div>';
        });
        querySelector.innerHTML = choicesHtml;
        var choiceElements = el.querySelectorAll('.quiz-choice');
        choiceElements.forEach(function (choice) {
          choice.addEventListener('click', function (e) {
            var selected = e.target;
            var parentElement = selected.closest('.quiz-choices');
            parentElement.classList.add('answer-selected');
            var _iterator = _createForOfIteratorHelper(parentElement.getElementsByClassName('quiz-choice')),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var element = _step.value;
                element.classList.remove('selected-answer');
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            selected.closest('.quiz-choice').classList.add('selected-answer');
            var value = selected.getAttribute('data-value');
            if (showRightOrWrongBox) {
              wrapperElement.classList.remove('correct-response');
              wrapperElement.classList.remove('wrong-response');
              if (value === question.correctAnswer) {
                wrapperElement.classList.add('correct-response');
              } else {
                wrapperElement.classList.add('wrong-response');
              }
            }
            question.setNewValue(value);
          });
        });
      };
      question.onItemValuePropertyChanged.add(createCustomSurvey);
      question.registerFunctionOnPropertyValueChanged("choices", createCustomSurvey);
      createCustomSurvey();
    },
    willUnmount: function willUnmount(question, el) {},
    pdfQuestionType: "text"
  };
  var _default = Quiz;
  _exports.default = _default;
});
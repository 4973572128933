define("momentumhub/controllers/inbox", ["exports", "momentumhub/config/environment", "momentumhub/constants/notification-settings"], function (_exports, _environment, _notificationSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    selectedStudy: null,
    //component handeling
    receivedMessage: true,
    sentmessage: false,
    writeMessage: false,
    readMessage: false,
    openedmessage: "",
    userMessage: '',
    messages: [],
    receivedMessages: [],
    selectedMessage: [],
    selecetdUsers: null,
    emberPowerSelect: null,
    selectUsersDisabled: false,
    //Pages
    page: 1,
    previousPage: "",
    nextPage: "",
    totalPages: 0,
    sendToAll: false,
    userRoles: [{
      label: 'Researcher',
      value: 'RS',
      isChecked: false
    }, {
      label: 'Clinician',
      value: 'CL',
      isChecked: false
    }, {
      label: 'Guardian',
      value: 'PR',
      isChecked: false
    }, {
      label: 'Participant',
      value: 'PT',
      isChecked: false
    }],
    notificationSettings: _notificationSettings.default,
    selectedNotificationSetting: {
      label: "Post and Notify",
      value: "post_and_notify"
    },
    messageTypes: [{
      label: 'Email',
      value: 'email',
      isChecked: false
    }, {
      label: 'SMS',
      value: 'sms',
      isChecked: false
    }],
    actions: {
      openDropDown: function openDropDown() {
        $(".dropdown dd ul").slideToggle();
      },
      registerAPI: function registerAPI(emberPowerSelect) {
        if (!this.emberPowerSelect) {
          this.emberPowerSelect = emberPowerSelect;
        }
      },
      selectStudy: function selectStudy(value) {
        this.set('selectedStudy', value);
        console.log(value);
      },
      selectUsers: function selectUsers(value) {
        this.set('selectedUsers', value);
        console.log(value);
      },
      updateNotificationSettings: function updateNotificationSettings(value) {
        this.set('selectedNotificationSetting', value);
      },
      checkboxClick: function checkboxClick(id) {
        console.log(id);
        var ele = document.getElementById(id);
        var title = $(ele).closest('.mutliSelect').find('input[type="checkbox"]').val();
        title = $(ele).val() + ",";
        if ($(ele).is(':checked')) {
          var html = '<span title="' + title + '">' + title + '</span>';
          $('.multiSel').append(html);
          $(".hida").hide();
        } else {
          $('span[title="' + title + '"]').remove();
          var ret = $(".hida");
          $('.dropdown dt a').append(ret);
        }
      },
      searchStudies: function searchStudies(term) {
        return this.store.query('study', {
          search: term
        });
      },
      searchUsers: function searchUsers(term) {
        var roles = this.get('userRoles').filterBy('isChecked', true).mapBy('value');
        var queryParams = {
          search: term,
          roles: roles.join(',')
        };
        if (this.get('selectedStudy')) {
          queryParams['study'] = this.get('selectedStudy').id;
        }
        return this.store.query('user', queryParams);
      },
      chooseUser: function chooseUser(type) {
        if (type == "researcher") {
          this.set('participantSelected', false);
          this.set('receivedMessage', true);
          this.set('sentmessage', false);
          this.set('writeMessage', false);
          this.set('readMessage', false);
        } else {
          this.set('participantSelected', true);
          this.set('receivedMessage', false);
          this.set('sentmessage', true);
          this.set('writeMessage', false);
          this.set('readMessage', false);
        }
        this.set('page', 1);
        this.getMessages();
      },
      viewMessage: function viewMessage(message) {
        if (this.receivedMessage) {
          this.set('openedmessage', "rec");
          this.setMessagetoRead(message);
        }
        if (this.sentmessage) {
          this.set('openedmessage', "sent");
        }
        this.set('receivedMessage', false);
        this.set('sentmessage', false);
        this.set('writeMessage', false);
        this.set('readMessage', true);
        this.set('selectedMessage', message);
      },
      closeMessage: function closeMessage() {
        if (this.openedmessage == "rec") {
          this.set('receivedMessage', true);
          this.set('sentmessage', false);
        }
        if (this.openedmessage == "sent") {
          this.set('sentmessage', true);
          this.set('receivedMessage', false);
        }
        if (this.writeMessage) {
          this.send('chooseUser', "researcher");
        }
        this.set('writeMessage', false);
        this.set('readMessage', false);
        this.set('selectedMessage', []);
      },
      createMessage: function createMessage() {
        this.set('receivedMessage', false);
        this.set('sentmessage', false);
        this.set('writeMessage', true);
        this.set('readMessage', false);
      },
      previousButton: function previousButton() {
        var page = this.page;
        page = +page - 1;
        this.set("page", page);
        this.getMessages();
      },
      mextButton: function mextButton() {
        var page = this.page;
        page = +page + 1;
        this.set("page", page);
        this.getMessages();
      },
      sendMessage: function sendMessage() {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var messageTypes = this.messageTypes.filterBy('isChecked', true).mapBy('value').join(',');
        if (that.userMessage) {
          if (that.selectedStudy) {
            if (that.selectedUsers || that.sendToAll) {
              if (messageTypes.length > 0) {
                var selectedUsers = this.selectedUsers;
                if (selectedUsers) {
                  selectedUsers = selectedUsers.mapBy('id');
                } else {
                  selectedUsers = [];
                }
                var data_to_api = {
                  "message": this.userMessage,
                  "send_to_all": this.sendToAll,
                  "study": this.selectedStudy.id,
                  "users": selectedUsers,
                  "roles": this.userRoles.filterBy('isChecked', true).mapBy('value').join(','),
                  "message_types": this.messageTypes.filterBy('isChecked', true).mapBy('value').join(','),
                  "notification_settings": this.selectedNotificationSetting.value
                };
                $.ajax({
                  url: _environment.default.APP.API_HOST + '/messages',
                  type: 'POST',
                  data: JSON.stringify({
                    'message': data_to_api
                  }),
                  headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Content-Type": "application/json"
                  },
                  success: function success(res) {
                    that.newAlert("Message sent successfully.", "Message", "Success");
                    that.getMessages();
                    that.set('receivedMessage', false);
                    that.set('sentmessage', true);
                    that.set('writeMessage', false);
                    that.set('readMessage', false);
                    that.set('selectedMessage', []);
                  },
                  error: function error(err, xH) {
                    var str = err.responseText;
                    str = str.split(':["').pop();
                    str = str.split('"]')[0];
                    that.newAlert(str, "Message", "Failure");
                  }
                });
              } else {
                that.newAlert("Select either Email or SMS", 'Type', 'Warning');
              }
            } else {
              that.newAlert("Please select at least one recipient, or send to all", "Study", "Warning");
            }
          } else {
            that.newAlert("Study field is mandatory.", "Study", "Warning");
          }
        } else {
          that.newAlert("Message field is mandatory.", "Message", "Warning");
        }
      }
    },
    setMessagetoRead: function setMessagetoRead(message) {
      var that = this;
      if (message.unread) {
        var accessToken = this.get('session.data.authenticated.access_token');
        var url = _environment.default.APP.API_HOST + '/notifications/' + message.id + '/mark-as-read';
        $.ajax({
          url: url,
          method: 'POST',
          data: {},
          headers: {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          success: function success() {
            message.set('unread', false);
          }
        });
        setTimeout(function () {
          that.send('refreshDashboardNotifications');
        }, 2000);
      }
    },
    getMessages: function getMessages() {
      var that = this;
      var viewingId = this.session.get('data.viewingId');
      var userType = this.session.get('data.user_type');
      var viewingShow_guardian_progress_messages = this.session.get('data.viewingShow_guardian_progress_messages');
      if (viewingId && (viewingShow_guardian_progress_messages || userType != 'PR')) {
        var userId = this.session.get('data.userId');
        this.set('messages', []);
        this.store.query('notification', {
          page: that.page,
          participant_lookup: viewingId
        }).then(function (notifications) {
          that.set('messages', notifications);
          that.set('previousPage', notifications.meta.previous);
          that.set('nextPage', notifications.meta.next);
          if (notifications.meta.count) {
            var totalPages = notifications.meta.count / 15;
            if (totalPages % 1 != 0) {
              that.set('totalPages', parseInt(totalPages) + 1);
            } else {
              that.set('totalPages', parseInt(totalPages));
            }
          }
        });
      } else {
        var userId = this.session.get('data.userId');
        var recipient = "";
        var actor_object_id = "";
        var that = this;
        if (this.receivedMessage) {
          recipient = userId;
          actor_object_id = "";
        }
        if (this.sentmessage) {
          recipient = "";
          actor_object_id = userId;
        }
        this.set('messages', []);
        this.store.query('notification', {
          page: that.page,
          recipient: recipient,
          actor_object_id: actor_object_id
        }).then(function (notifications) {
          that.set('messages', notifications);
          /* notifications.forEach(function (notification) {
              console.log(notification.timestamp);
              that.getUser(notification);
          }) */
          that.set('previousPage', notifications.meta.previous);
          that.set('nextPage', notifications.meta.next);
          if (notifications.meta.count) {
            var totalPages = notifications.meta.count / 15;
            if (totalPages % 1 != 0) {
              that.set('totalPages', parseInt(totalPages) + 1);
            } else {
              that.set('totalPages', parseInt(totalPages));
            }
          }
        });
      }
    },
    getUser: function getUser(notification) {
      var that = this;
      var userId = "";
      if (this.receivedMessage) {
        userId = notification.get('actor_object_id');
      }
      if (this.sentmessage) {
        userId = notification.get('recipient');
      }
      this.store.query('user', {
        all_users: true,
        id: userId
      }).then(function (users) {
        if (users.get('firstObject')) {
          var user = users.get('firstObject');
          var obj = {
            "user": user,
            "notification": notification
          };
          that.messages.pushObject(obj);
        }
      });
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function newAlert(message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }

    //
    //    $(".dropdown dd ul li a").on('click', function() {
    //      $(".dropdown dd ul").hide();
    //    });
    //
    //    function getSelectedValue(id) {
    //      return $("#" + id).find("dt a span.value").html();
    //    }
    //
    //    $(document).bind('click', function(e) {
    //      var $clicked = $(e.target);
    //      if (!$clicked.parents().hasClass("dropdown")) $(".dropdown dd ul").hide();
    //    });
    //
    //    function checkboxClick(e){
    //
    //      var title = $(e).closest('.mutliSelect').find('input[type="checkbox"]').val(),
    //        title = $(e).val() + ",";
    //
    //      if ($(e).is(':checked')) {
    //        var html = '<span title="' + title + '">' + title + '</span>';
    //        $('.multiSel').append(html);
    //        $(".hida").hide();
    //      } else {
    //        $('span[title="' + title + '"]').remove();
    //        var ret = $(".hida");
    //        $('.dropdown dt a').append(ret);
    //
    //      }
    //    }
  });
  _exports.default = _default;
});
define("momentumhub/routes/assesment-results", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    model: function model() {
      var viewingUserType = this.get('session.data.viewingUserType') || this.get('session.data.user_type');
      var viewingAs = this.get('session.data.viewingId');
      return Ember.RSVP.hash({
        reports: this.store.query('user-report', {
          'user_role': viewingUserType,
          'user_id': viewingAs
        })
      });
    }
  });
  _exports.default = _default;
});
define("momentumhub/routes/create-survey", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment", "survey-creator", "survey-knockout", "momentumhub/helpers/custom-widgets"], function (_exports, _authenticatedRouteMixin, _environment, _surveyCreator, _surveyKnockout, _customWidgets) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model(param) {
      var surveyId = "";
      if (param.id) {
        surveyId = param.id;
        var that = this;
        this.store.query('survey', {
          identifier: param.id
        }).then(function (surveys) {
          var survey = surveys.get('firstObject');
          var surveyId = survey.get('id');
          that.controllerFor("create-survey").set('survey', survey);
          that.controllerFor("create-survey").set('surveyId', surveyId);
        });
        return Ember.RSVP.hash({
          survey_id: surveyId
        });
      }
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      },
      willTransition: function willTransition(transition) {
        this.controllerFor("create-survey").resetTimmers();
        this.controllerFor("create-survey").ifNotSaved(transition);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor("create-survey").set('exit', false);
      this.controllerFor("create-survey").set('surveyChanged', false);
      this.loadSurveyBuilder(model);
    },
    /* Loads the survey Builder */
    loadSurveyBuilder: function loadSurveyBuilder(model) {
      var survey_Id = model.survey_id;
      var accessToken = this.get('session.data.authenticated.access_token');
      var that = this;
      $.ajax({
        url: _environment.default.APP.API_HOST + '/surveys?identifier=' + survey_Id,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        success: function success(res) {
          var surveys = res.survey;
          var survey = surveys[0];
          var surveyId = survey.id;
          var lock_assigned_to = survey.lock_assigned_to;
          that.controllerFor("create-survey").set('surveyId', surveyId);
          that.controllerFor("create-survey").set('lockAssignedTo', lock_assigned_to);
          that.controllerFor("create-survey").sendActivityToBackend();
          var options = {
            showLogicTab: true
            // questionTypes: ["text", "checkbox"]
          };

          setTimeout(function () {
            //Destroy survey creator

            // #todo, eliminate Survey from Global
            window.Survey = window.Survey || _surveyKnockout.default;
            //Load Custom widgets if its not rendered
            if ($("#survey-widget-ratinggauge").length == 0) {
              (0, _customWidgets.default)();
            }
            var surveyCreator = new _surveyCreator.default.SurveyCreator("creatorElement", options);
            _surveyCreator.default.StylesManager.applyTheme("modern");
            surveyCreator.showToolbox = "right";
            surveyCreator.showPropertyGrid = "right";
            surveyCreator.rightContainerActiveItem("toolbox");
            surveyCreator.haveCommercialLicense = true;
            that.controllerFor("create-survey").set('surveyCreator', surveyCreator);

            //Auto save in localstorage
            var localStorageName = "survey-" + survey_Id;
            surveyCreator.isAutoSave = true;
            surveyCreator.showState = true;
            surveyCreator.saveSurveyFunc = function (saveNo, callback) {
              // console.log(surveyCreator.text);
              window.localStorage.setItem(localStorageName, surveyCreator.text);
              callback(saveNo, true);
              that.controllerFor("create-survey").send('surveyUpdated');
            };
            var defaultJSON = {
              pages: [{
                name: 'page1'
              }],
              clearInvisibleValues: "none"
            };
            surveyCreator.text = window.localStorage.getItem(localStorageName) || JSON.stringify(defaultJSON);
            console.log('render route survey');
            surveyCreator.render("surveyCreatorDivElementID");
          }, 100);
        },
        error: function error(err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
        }
      });
    }
  });
  _exports.default = _default;
});
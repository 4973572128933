define("momentumhub/routes/user-profile/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model() {
      var user_email = this.session.get('data.viewingEmail');
      var user_type = this.session.get('data.user_type');
      if (!user_email || user_email.length === 0 || user_type != 'CL') user_email = this.session.get('data.user_email');
      return Ember.RSVP.hash({
        users: this.store.query('user', {
          email__iexact: user_email
        })
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var userModel = model.users;
      if (userModel) {
        var that = this;
        var user = userModel.get('firstObject');
        this.setupPermissions(user);
        this.controllerFor("user_profile.index").set('first_name', user.get('first_name'));
        this.controllerFor("user_profile.index").set('last_name', user.get('last_name'));
        this.controllerFor("user_profile.index").set('dob', user.get('age'));
        this.controllerFor("user_profile.index").set('userId', user.get('id'));
        this.controllerFor("user_profile.index").set('dark_theme', user.get('dark_theme'));
        this.controllerFor("user_profile.index").set('user_type', user.get('user_type'));

        // this.controllerFor("user_profile").set('selected_theme', user.get('selected_theme'));
        this.controllerFor("user_profile.index").set('mobile', user.get('mobile'));
        this.controllerFor("user_profile.index").set('parentparticipants', []);
        var age = user.get('age');
        if (age <= 12) {
          this.controllerFor("user_profile.index").set('isTeenager', false);
        } else if (age > 12) {
          this.controllerFor("user_profile.index").set('isTeenager', true);
        }

        // For Guardian Users
        if (user.get('user_type') == "PR") {
          this.store.query('parentparticipant', {
            parent: user.get('id')
          }).then(function (parentparticipants) {
            that.controllerFor("user_profile.index").set('parentparticipants', parentparticipants);
          });
          var viewingName = this.session.get('data.viewingName');
          if (viewingName) {
            setTimeout(function () {
              $('select option:contains(' + viewingName + ')').prop('selected', true);
            }, 1000);
          }
        }
      }
    },
    getAge: function getAge(birthday) {
      // birthday is a date
      var dob = new Date(birthday);
      var month_diff = Date.now() - dob.getTime();
      var age_dt = new Date(month_diff);
      var year = age_dt.getUTCFullYear();
      var age = Math.abs(year - 1970);
      return age;
    },
    setupPermissions: function setupPermissions(user) {
      if (user.get('user_type') == "PT") {
        var age = user.get('age');
        if (age < 13) {
          this.controllerFor("user_profile.index").set('ptType', "child");
        }
        if (age < 16 && age > 12) {
          this.controllerFor("user_profile.index").set('ptType', "teen");
        }
        if (age > 15) {
          this.controllerFor("user_profile.index").set('ptType', "youth");
        }
        setTimeout(function () {
          var show_guardian_progress_messages = user.get('show_guardian_progress_messages');
          var show_guardian_assessment_reports = user.get('show_guardian_assessment_reports');
          var show_guardian_progress_charts = user.get('show_guardian_progress_charts');
          var show_guardian_feelings_tracker = user.get('show_guardian_feelings_tracker');
          if (show_guardian_progress_messages) {
            $('#guardian_progress_messages').prop('checked', true);
          }
          if (show_guardian_assessment_reports) {
            $('#guardian_assessment_reports').prop('checked', true);
          }
          if (show_guardian_progress_charts) {
            $('#guardian_progress_reports').prop('checked', true);
          }
          if (show_guardian_feelings_tracker) {
            $('#guardian_feelings_tracker').prop('checked', true);
          }
        }, 100);
        this.controllerFor("user_profile.index").getClinicianGuardian();
      }
    }
  });
  _exports.default = _default;
});
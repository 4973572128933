define("momentumhub/routes/mood-boosters", ["exports", "momentumhub/mixins/guardian-session-mixin"], function (_exports, _guardianSessionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_guardianSessionMixin.default, {
    model: function model(param) {
      return Ember.RSVP.hash({
        events: this.store.findAll('mood-booster-event')
      });
    }
  });
  _exports.default = _default;
});
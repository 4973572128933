define("momentumhub/controllers/usermanagement/sites", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    researchergroups: [],
    previousPage: "",
    nextPrevious: "",
    page: 1,
    totalPages: 1,
    search: "",
    delayTimer: "",
    researcherSites: [],
    siteSelected: [],
    user_type: "",
    isParticipantSelected: false,
    isPaginationAllowed: true,
    actions: {
      openCreateSite: function openCreateSite() {
        $('.hover_bkgr_fricc-group-comp').show();
      },
      searchSite: function searchSite() {
        var that = this;
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.getSites();
        }, 1000);
      },
      refreshRouter: function refreshRouter() {
        this.getSites();
      },
      closeCreateGroup: function closeCreateGroup() {
        $('.hover_bkgr_fricc-group-comp').hide();
      },
      chooseUser: function chooseUser(type) {
        if (type == "researcher") {
          this.set('isParticipantSelected', false);
          $('.res').css({
            "background-color": '#344D90'
          });
          $('.res').css({
            "color": 'white'
          });
          $('.pt').css({
            "background-color": 'white'
          });
          $('.pt').css({
            "color": 'black'
          });
        } else {
          this.set('isParticipantSelected', true);
          $('.pt').css({
            "background-color": '#344D90'
          });
          $('.pt').css({
            "color": 'white'
          });
          $('.res').css({
            "background-color": 'white'
          });
          $('.res').css({
            "color": 'black'
          });
        }
        this.set('page', 1);
      },
      //******************** Create Group ************ */
      createSite: function createSite() {
        var accessToken = this.get('session.data.authenticated.access_token');
        var that = this;
        var siteNName = this.get('siteNName');
        var site_admin = null;
        if (this.user_type == "PA") {
          var user_id = this.session.get('data.userId');
          site_admin = user_id;
        }
        if (siteNName) {
          var data_to_api = {
            "name": siteNName,
            "active": true
          };
          $.ajax({
            url: _environment.default.APP.API_HOST + '/mommentumhubsites',
            type: 'POST',
            data: data_to_api,
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function success(res) {
              var siteId = res.mommentumhubsite.id;
              var data_to_api1 = {
                "site_admin": site_admin
              };
              $.ajax({
                url: _environment.default.APP.API_HOST + '/mommentumhubsites/' + siteId,
                type: 'PATCH',
                data: data_to_api1,
                headers: {
                  "Authorization": "Bearer " + accessToken
                },
                success: function success(res) {
                  that.set('siteNName', "");
                  $('.hover_bkgr_fricc-group-comp').hide();
                  that.newAlert("Group Created successfully.", "Group", "Success");
                  that.getSites();
                },
                error: function error(err, xH) {
                  var str = err.responseText;
                  str = str.split(':["').pop();
                  str = str.split('"]')[0];
                  that.newAlert(str, "Add User", "Failure");
                }
              });
            },
            error: function error(err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              that.newAlert(str, "Add User", "Failure");
            }
          });
        } else {
          this.showError("siteNName");
        }
      },
      previousButton: function previousButton() {
        var page = this.page;
        page = +page - 1;
        this.set("page", page);
        this.getGroups();
      },
      mextButton: function mextButton() {
        var page = this.page;
        page = +page + 1;
        this.set("page", page);
        this.getGroups();
      },
      //**************** Delete Group ************ */
      deleteSite: function deleteSite(researcherSite) {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var r = confirm("Are you sure you want to delete this site ?");
        if (r) {
          $.ajax({
            url: _environment.default.APP.API_HOST + '/mommentumhubsites/' + researcherSite.id,
            type: 'DELETE',
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function success(res) {
              that.newAlert("Site deleted successfully.", "Site", "Success");
              that.getSites();
            },
            error: function error(err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              that.newAlert(str, "Add User", "Failure");
            }
          });
        }
      },
      updateSite: function updateSite(researcherSite, option) {
        this.set('siteSelected', researcherSite);
        if (option == 1) {
          $('.hover_bkgr_fricc-addsite-comp').show();
        } else {
          $('.hover_bkgr_fricc-assignsiteadmin-comp').show();
        }
      }
    },
    getSites: function getSites() {
      var that = this;
      that.set('researcherSites', []);
      that.set('isPaginationAllowed', true);
      var search = this.get('search');
      this.store.query('mommentumhubsite', {
        page: that.page,
        name__icontains: search
      }).then(function (researcherSites) {
        that.set('researcherSites', researcherSites);
        that.set('previousPage', researcherSites.meta.previous);
        that.set('nextPrevious', researcherSites.meta.next);
        if (researcherSites.meta.count) {
          var totalPages = researcherSites.meta.count / 15;
          if (totalPages % 1 != 0) {
            that.set('totalPages', parseInt(totalPages) + 1);
          } else {
            that.set('totalPages', parseInt(totalPages));
          }
        }
      });
    },
    getSiteAdminSites: function getSiteAdminSites() {
      var that = this;
      that.set('researcherSites', []);
      that.set('isPaginationAllowed', false);
      var user_email = this.session.get('data.user_email');
      this.store.query('user', {
        email__iexact: user_email
      }).then(function (users) {
        var user = users.get('firstObject');
        var sites = user.get('get_all_available_sites');
        sites.forEach(function (site) {
          that.store.findRecord('mommentumhubsite', site).then(function (s) {
            that.researcherSites.pushObject(s);
          });
        });
      });
    },
    showError: function showError(id) {
      $("#" + id).addClass("error");
      setTimeout(function () {
        $("#" + id).removeClass("error");
      }, 2000);
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function newAlert(message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }
  });
  _exports.default = _default;
});
define("momentumhub/controllers/climbing-plans/index", ["exports", "momentumhub/config/environment", "survey-knockout"], function (_exports, _environment, Survey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var accessToken = localStorage.getItem('access_token_hedgedoc');
    },
    classNames: ['css-framework-fancy-class'],
    targetAttachment: "none",
    participantId: 0,
    header: {
      isHeader: true
    },
    footer: {
      isFooter: true
    },
    body: {
      isBody: true
    },
    form1: {
      isForm1: true
    },
    form2: {
      isForm2: true
    },
    actions: {}
  });
  _exports.default = _default;
});
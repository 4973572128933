define("momentumhub/controllers/change-focus", ["exports", "momentumhub/config/environment", "survey-knockout", "momentumhub/utils"], function (_exports, _environment, Survey, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var accessToken = localStorage.getItem('access_token_hedgedoc');
    },
    classNames: ['css-framework-fancy-class'],
    targetAttachment: "none",
    isShowingModal: true,
    participantId: 0,
    header: {
      isHeader: true
    },
    footer: {
      isFooter: true
    },
    body: {
      isBody: true
    },
    form1: {
      isForm1: true
    },
    form2: {
      isForm2: true
    },
    actions: {
      showTipsAndTricksModule: function showTipsAndTricksModule() {
        var moduleId = _environment.default.APP.CHANGING_FOCUS_TIPS_AND_TRICKS_CHILD;
        if ((0, _utils.getParticipantCategory)() === 'teen') {
          moduleId = _environment.default.APP.CHANGING_FOCUS_TIPS_AND_TRICKS_TEEN;
        }
        this.transitionToRoute('modulePreview', moduleId);
      }
    }
  });
  _exports.default = _default;
});
define("momentumhub/routes/user-profile/rewards", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var userId = this.session.get('data.viewingId');
      if (!userId || userId.length === 0) userId = this.session.get('data.userId');
      return Ember.RSVP.hash({
        rewards: this.store.query('userrewardvar', {
          'user': userId
        })
      });
    } // // have moved this to the controller: `controller/user-profile/rewards.js`
    // setupController(controller, model) {
    // this._super(controller, model);
    // var userModel = model.users;
    //     if (userModel) {
    //         const that = this;
    //         var user = userModel.get('firstObject');
    //         this.setupPermissions(user);
    //         this.controllerFor("user_profile.index").set('first_name', user.get('first_name'));
    //         this.controllerFor("user_profile.index").set('last_name', user.get('last_name'));
    //         this.controllerFor("user_profile.index").set('dob', user.get('age'));
    //         this.controllerFor("user_profile.index").set('userId', user.get('id'));
    //         this.controllerFor("user_profile.index").set('dark_theme', user.get('dark_theme'));
    //         this.controllerFor("user_profile.index").set('user_type', user.get('user_type'));
    //         // this.controllerFor("user_profile").set('selected_theme', user.get('selected_theme'));
    //         this.controllerFor("user_profile.index").set('mobile', user.get('mobile'));
    //         this.controllerFor("user_profile.index").set('parentparticipants', []);
    //         var age = user.get('age');
    //         if (age <= 12) {
    //             this.controllerFor("user_profile.index").set('isTeenager', false);
    //             this.controllerFor("user_profile.index").set('isChild', true);
    //         }
    //         else if (age > 12) {
    //             this.controllerFor("user_profile.index").set('isTeenager', true);
    //             this.controllerFor("user_profile.index").set('isChild', false);
    //         }
    //     }
    // }
  });
  _exports.default = _default;
});
define("momentumhub/models/event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _DS$Model$extend;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    name: _emberData.default.attr(),
    identifier: _emberData.default.attr(),
    action: _emberData.default.attr(),
    receiver: _emberData.default.attr(),
    sender: _emberData.default.attr(),
    message: _emberData.default.attr(),
    rule: _emberData.default.belongsTo('rule'),
    is_node_event: _emberData.default.attr("boolean"),
    delivery_time: _emberData.default.attr(),
    delivery_timestamp: _emberData.default.attr(),
    delivery_type: _emberData.default.attr(),
    delivery_time_after_creation: _emberData.default.attr(),
    event_fire_frequency: _emberData.default.attr(),
    days_between_fire_frequency: _emberData.default.attr()
  }, _defineProperty(_DS$Model$extend, "delivery_time_after_creation", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "reward_operation", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "reward_change", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "reward_variable", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "module_id", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "module_availability", _emberData.default.attr("boolean")), _defineProperty(_DS$Model$extend, "is_resource", _emberData.default.attr("boolean")), _defineProperty(_DS$Model$extend, "delay_in_hours", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "survey_id", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "report_type", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "notification_settings", _emberData.default.attr()), _DS$Model$extend));
  _exports.default = _default;
});
define("momentumhub/components/modules/create-module", ["exports", "jquery", "momentumhub/config/environment", "blockly/msg/en"], function (_exports, _jquery, _environment, _en) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    tags: [],
    isDeleteTag: true,
    eachTagDelete: false,
    mediaPreview: "",
    actions: {
      /********* Close assets component *********/
      close: function close() {
        (0, _jquery.default)('.hover_bkgr_fricc-study-module').hide();
        this.set('tags', []);
        this.set('isDeleteTag', true);
        this.set('eachTagDelete', false);
        this.set('mediaPreview', "");
        (0, _jquery.default)('#isPageChecked').prop('checked', false);
        (0, _jquery.default)('#shouldHideSaveButtons').prop('checked', false);
      },
      /* Preview the media */
      previewMedia: function previewMedia() {
        var uploadmediainput = document.getElementById('modulethumbnail');
        var tmppath = URL.createObjectURL(uploadmediainput.files[0]);
        this.set('mediaPreview', tmppath);
      },
      /********* Create Tags *********/
      createTag: function createTag() {
        var that = this;
        var tag = this.get('tag');
        if (tag) {
          if (this.tags.includes(tag)) {
            (0, _jquery.default)('.note-tag').animate({
              opacity: '1'
            }, 300);
            setTimeout(function () {
              (0, _jquery.default)('.note-tag').animate({
                opacity: '0'
              }, 300);
            }, 3000);
          } else {
            if (tag.includes(",")) {
              var tagsArray = tag.split(',');
              tagsArray.forEach(function (element) {
                if (that.tags.includes(element)) {
                  (0, _jquery.default)('.note-tag').animate({
                    opacity: '1'
                  }, 300);
                  setTimeout(function () {
                    (0, _jquery.default)('.note-tag').animate({
                      opacity: '0'
                    }, 300);
                  }, 3000);
                } else {
                  that.tags.pushObject(element);
                  that.set('tag', '');
                }
              });
            } else {
              this.tags.pushObject(tag);
              this.set('tag', '');
            }
          }
        }
      },
      /********* Delete Tags *********/
      deleteTag: function deleteTag(index) {
        var r = confirm("Are you sure you want to delete this tag");
        if (r) {
          this.tags.removeAt(index);
          if (this.tags.length === 0) {
            this.set('isDeleteTag', true);
            this.set('eachTagDelete', false);
          }
        }
      },
      /********* Activate Delete Tag buttons *********/
      activateDeleteTag: function activateDeleteTag() {
        if (this.isDeleteTag) {
          this.set('isDeleteTag', false);
          this.set('eachTagDelete', true);
        } else {
          this.set('isDeleteTag', true);
          this.set('eachTagDelete', false);
        }
      },
      /* Create Module and send user to the create-module page */
      createNewModule: function createNewModule() {
        var self = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var moduleName = this.get('moduleName');
        var tags = this.get('tags');
        var dateCreated = new Date();
        var status = "IP";
        var mod_description = this.get('module_description');
        var isPageChecked = (0, _jquery.default)('#isPageChecked').is(':checked');
        var shouldHideSaveButtons = (0, _jquery.default)('#shouldHideSaveButtons').is(':checked');
        var onboardingIsChecked = (0, _jquery.default)('#onboardingIsChecked').is(':checked');
        var mediaFile = document.getElementById('modulethumbnail');
        if (moduleName) {
          if (mod_description) {
            var formdata = new FormData();
            formdata.append("name", moduleName);
            formdata.append("status", status);
            formdata.append("tags", JSON.stringify(tags));
            formdata.append("date_created", dateCreated);
            formdata.append("mod_description", mod_description);
            formdata.append("current_page", isPageChecked);
            formdata.append('hide_save_buttons', shouldHideSaveButtons);
            formdata.append("on_boarding", onboardingIsChecked);
            if (mediaFile.files[0]) {
              formdata.append("thumbnail_image", mediaFile.files[0], mediaFile.files[0].name);
            }
            _jquery.default.ajax({
              url: _environment.default.APP.API_HOST + '/modules',
              type: 'POST',
              headers: {
                "Authorization": "Bearer " + accessToken
                // "Content-Type": "application/json"
              },

              processData: false,
              contentType: false,
              data: formdata,
              success: function success(res) {
                var moduleId = res.module.id;
                var moduleIdentifier = res.module.identifier;
                self.tags.length = 0;
                self.set('moduleName', "");
                self.set('tags', []);
                self.set('module_description', "");
                document.getElementById('modulethumbnail').value = null;
                (0, _jquery.default)('#isPageChecked').prop('checked', false);
                (0, _jquery.default)('#shouldHideSaveButtons').prop('checked', false);
                (0, _jquery.default)('#onboardingIsChecked').prop('checked', false);
                self.set('mediaPreview', "");
                (0, _jquery.default)('.hover_bkgr_fricc-study-module').hide();
                self.get('router').transitionTo('create-module', moduleIdentifier);
              },
              error: function error(err, xH) {
                var errorMessage = err.responseText;
                if (errorMessage.includes("Module with this name already exists.")) {
                  (0, _jquery.default)('.modulenameexist').show();
                  setTimeout(function () {
                    (0, _jquery.default)('.modulenameexist').hide();
                  }, 3000);
                }
              }
            });
          } else {
            (0, _jquery.default)("#modulenameinput123").addClass("error");
            setTimeout(function () {
              (0, _jquery.default)("#modulenameinput123").removeClass("error");
            }, 2000);
          }
        } else {
          (0, _jquery.default)("#modulenameinput").addClass("error");
          setTimeout(function () {
            (0, _jquery.default)("#modulenameinput").removeClass("error");
          }, 2000);
        }
      }
    }
  });
  _exports.default = _default;
});